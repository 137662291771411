import {Injectable} from '@angular/core';
import {YoutubePlaylist} from '../models/YoutubePlaylist';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class YoutubeService {

  private playlists: YoutubePlaylist[];
  private playlist: YoutubePlaylist;
  private youtubeRef: AngularFirestoreCollection<YoutubePlaylist>;

  constructor(private afs: AngularFirestore) {
    this.playlists = [];
    this.youtubeRef = this.afs.collection<YoutubePlaylist>('youtube-playlists');
  }

  loadAllPlaylists() {
    return new Promise<YoutubePlaylist[]>((resolve, reject) => {
      this.youtubeRef.valueChanges().forEach((element) => {
        element.forEach((el) => {
          this.playlists.push(el);
        });
        // this.playlists.sort(() => Math.random() - 0.5);
        resolve(this.playlists);
      });
    });
  }

  loadEntirePlaylist(playlistID) {
    return new Promise<YoutubePlaylist>((resolve, reject) => {
      this.youtubeRef.doc(playlistID).valueChanges().subscribe(data => {
        this.playlist = data as YoutubePlaylist;
        resolve(this.playlist);
      });
    });
  }

  updatePlaylist(playlistID: string, playlist: YoutubePlaylist) {
    this.youtubeRef.doc(playlistID).update(playlist);
  }
}
