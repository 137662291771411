import {Component, OnInit} from '@angular/core';
import {CourseService} from '../../../services/course.service';
import {Course} from '../../../models/Course';
import {Section} from '../../../models/Section';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-database-populate',
  templateUrl: './database-populate.component.html',
  styleUrls: ['./database-populate.component.css']
})
export class DatabasePopulateComponent implements OnInit {

  course: Course;
  showUpload: boolean;

  constructor(private courseService: CourseService) {
  }

  ngOnInit() {
    this.loadCourse('ios-11-development');
    this.showUpload = false;
  }

  swapViews() {
    this.showUpload = !this.showUpload;
  }

  loadCourse(courseId) {
    this.courseService.loadEntireCourse(courseId).then(val => {
      this.course = val;
      // console.log(this.course);
      if (!this.course.topics) {
        this.course.topics = ['', '', '', '', '', '', '', 'And more!'];
      }

      if (!this.course.skillsTitle) {
        this.course.skillsTitle = ['', '', ''];
        this.course.skillsSubtitle = ['', '', ''];
      }

      if (!this.course.projectsTitle) {
        this.course.projectsTitle = ['', '', ''];
        this.course.projectsImage = ['', '', ''];
      }
    });
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  addLecture(index) {
    this.course.sections[index].lectures.push({name: '', videoID: null});
  }

  addSection() {
    const blankLec = {name: '', videoID: null};
    const blankSec: Section = ({name: '', lectures: [blankLec]});
    this.course.sections.push(blankSec);
  }

  updateCourse() {
    console.log(this.course.sections[0].lectures);
    alert('Updating course');
    this.courseService.updateCourseSections(this.course.courseID, this.course.sections);
  }

  updateCourseInfo() {
    const c = this.course;
    delete c.sections;
    console.log(c);
    this.courseService.updateCourseInfo(this.course.courseID, c);
  }

  deleteLecture(sectionIndex, lectureIndex) {
    this.course.sections[sectionIndex].lectures.splice(lectureIndex, 1);
  }

  deleteSection(sectionIndex) {
    this.course.sections.splice(sectionIndex, 1);
  }

}
