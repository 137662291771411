import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})

export class TestimonialsComponent implements OnInit {
  isPricing = false;

  constructor(private router: Router) {
  }

  ngOnInit() {
    if (this.router.url === '/pricing') {
      this.isPricing = true;
    }
  }
}
