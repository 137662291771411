import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {Observable} from 'rxjs/Observable';
import {Exercise} from '../models/Exercise';
import {Course} from '../models/Course';

@Injectable({
  providedIn: 'root'
})
export class ExerciseService {
  exerciseCollection: AngularFirestoreCollection<Exercise>;
  exercises: Observable<Exercise[]>;
  e: Observable<Exercise>;
  exercise: Exercise;

  constructor(private afs: AngularFirestore) {
    this.exerciseCollection = this.afs.collection('exercises');
  }

  get Exercises() {
    this.exercises = this.exerciseCollection.valueChanges();
    return this.exercises;
  }
  exerciseDetails(id) {
    this.e = this.afs.collection('exercises').doc(id).valueChanges() as Observable<Exercise>;
    return (this.e);
  }

  loadEntireExercise(exerciseID) {
    return new Promise<Exercise>((resolve, reject) => {
      this.afs.collection('exercises').doc(exerciseID).valueChanges().subscribe(data => {
        this.exercise = data as Course;
        console.log(this.exercise);
        resolve(this.exercise);
      });
    });
  }

  updateExerciseInfo(exerciseID, exercise) {
    console.log(exercise);
    console.log(exerciseID);
    this.afs.collection('exercises').doc(exerciseID).update(exercise);
  }

  get createExercise() {
    this.exercise.exerciseID = Math.random().toString(36).slice(2, 8);
    const dataDoc = this.exerciseCollection.doc(this.exercise.exerciseID);
    // tslint:disable-next-line:max-line-length
    this.afs.collection('exercises', ref => ref.where('exerciseID', '==', this.exercise.exerciseID)).snapshotChanges().subscribe(res => {
      if (res.length > 0) {
        console.log('Match found');
      } else {
        this.exerciseCollection.doc('' + this.exercise.exerciseID).set({
        }).then(val => {
        });
      }
    });
    return(this.exercise.exerciseID);
  }



}
