import {Component, OnInit} from '@angular/core';
import {AllCourseCalendar, Calendar, CourseCalendar, AppUser} from '../../../models/AppUser';
import {UserService} from '../../../services/user.service';
import {Chart} from 'chart.js';
import {Dataset, UserDatasets} from '../../../models/Chart';
import {Location} from '@angular/common';
import {Class} from '../../../models/Class';


@Component({
  selector: 'app-teacherdashboard',
  templateUrl: './teacherdashboard.component.html',
  styleUrls: ['./teacherdashboard.component.css']
})
export class TeacherdashboardComponent implements OnInit {

  class: Class;
  classesTeaching: Class[];
  user: AppUser;

  constructor(private userService: UserService, private location: Location) {
  }

  ngOnInit() {
    this.class = {className: null, classID: null, instructorName: null};
    this.classesTeaching = [];

  }

  loadClasses() {
    this.user = this.userService.user;
    this.user.classesTeaching.forEach((el) => {
      this.userService.getClass(el).then((val) => {
        this.classesTeaching.push(val);
      });
    });
  }

  generateClassID() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 8; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  createClass() {
    this.class.classID = this.generateClassID();
    this.userService.createClass(this.class, this.user);
  }

  goToClass(classID: string) {
    location.href = '/teacher-dashboard/' + classID;
  }



}
