import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-lecture-overlay',
  templateUrl: './lecture-overlay.component.html',
  styleUrls: ['./lecture-overlay.component.css']
})
export class LectureOverlayComponent implements OnInit {

  @Input('showSignup') showSignup: boolean;
  loading = false;
  emailSent = false;
  email = '';

  constructor(private auth: AuthService) {
  }

  ngOnInit(): void {
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  async handleForm(e) {
    e.preventDefault();
    this.loading = true;
    const email = e.target.email.value;
    const name = e.target.first_name.value;
    if (this.validateEmail(email) && name !== '') {

      const verified = await this.auth.getSignInMethods(email);
      if (verified.length !== 0) {
        alert('An account with this email already exists. Please log in to continue.');
      } else {
        const confirmation = await this.auth.sendSignInLink(email);
        if (confirmation === 'success') {
          this.email = email;
          window.localStorage.setItem('nameForSignIn', name);
          this.emailSent = true;
        }
      }
    } else {
      alert('Please enter in a valid email and name.');
    }
    this.loading = false;
  }

}
