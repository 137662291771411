import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
    imageIndexes = [0, 0, 0, 0, 0];
    image_srcs = ['../../../../assets/img/pricing-assets/faq-show.png', '../../../../assets/img/pricing-assets/faq-hide.png'];


    constructor() {
    }

    ngOnInit(): void {
    }

    updateFAQImage(questionSelected) {
        if (this.imageIndexes[questionSelected] === 0) {
            this.imageIndexes[questionSelected] = 1;
        } else {
            this.imageIndexes[questionSelected] = 0;
        }
    }

}
