import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/functions';
import {AuthService} from '../../../services/auth.service';
import {CountryCurrency, LocationService} from '../../../services/location.service';
import {UserService} from '../../../services/user.service';
import {environment} from '../../../../environments/environment';
import {AppUser} from '../../../models/AppUser';
import {ActivatedRoute} from '@angular/router';

declare var Stripe; // : stripe.StripeStatic;

@Component({
  selector: 'app-payment-checkout',
  templateUrl: './payment-checkout.component.html',
  styleUrls: ['./payment-checkout.component.css']
})
export class PaymentCheckoutComponent implements OnInit, AfterViewInit {
  appUser: AppUser;
  userExists = false;
  discountCode: string;
  stripeCouponCode = '';
  showDiscount: boolean;
  discount = 0;
  finalPrice: number;
  showDetails: boolean;
  paymentText: string;
  dropdownPlan: string;
  CountryCurrency = CountryCurrency;
  paymentPlan = paymentPlan;
  prices = {
    usd: [19, 99],
    rs: [699, 3999]
  };
  private oldCountry: string = null;

  constructor(
    private auth: AuthService,
    private location: LocationService,
    private user: UserService,
    private functions: AngularFireFunctions,
    private route: ActivatedRoute
  ) {
    auth.appUser$.subscribe((appUser) => {
      this.appUser = appUser;
      if (appUser) {
        this.userExists = true;
        document.getElementById('first-name-input').setAttribute('value', appUser.personalInfo.firstName);
        // document.getElementById('last-name-input').setAttribute('value', appUser.personalInfo.firstName);
        document.getElementById('email-input').setAttribute('value', appUser.personalInfo.email);
      }
    });
  }

  @Input() chosenPlan: number;
  @ViewChild('cardElement') cardElement: ElementRef;
  @Output() paymentSuccessful: EventEmitter<any> = new EventEmitter<any>();

  stripe; // : stripe.Stripe;
  card;
  cardErrors;
  email: string;

  loading = false;
  confirmation;

  ngOnInit() {
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment === null || fragment === undefined || fragment === 'monthly') {
        this.setPaymentPlan(paymentPlan.monthly);
      } else {
        this.setPaymentPlan(paymentPlan.annual);
      }
    });
  }

  ngAfterViewInit(): void {
    this.stripe = Stripe(environment.stripeKey);
    const elements = this.stripe.elements();

    this.card = elements.create('card', {
      style: {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '20px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      },
      hidePostalCode: true
    });
    this.card.mount(this.cardElement.nativeElement);

    this.card.addEventListener('change', ({error}) => {
      this.cardErrors = error && error.message;
    });
  }

  // This updates the price after the render of the page
  ngDoCheck() {
    if (this.location.currency !== this.oldCountry) {
      this.route.fragment.subscribe((fragment: string) => {
        if (fragment === null || fragment === undefined || fragment === 'monthly') {
          this.setPaymentPlan(paymentPlan.monthly);
        } else {
          this.setPaymentPlan(paymentPlan.annual);
        }
      });
      this.oldCountry = this.location.currency;
    }
  }

  applyCoupon(code) {
    this.showDiscount = true;
    this.discountCode = code;
    this.stripeCouponCode = environment.discounts[code][this.chosenPlan][0];
    this.discount = environment.discounts[code][this.chosenPlan][1];
  }

  checkCoupon() {
    const el = <HTMLInputElement>document.getElementById('couponCode');
    if (el.value.trim() in environment.discounts) {
      this.applyCoupon(el.value.trim());
    } else {
      alert('Whoops! That coupon code did not work. Please try again.');
    }
  }

  formatPrice(number) {
    return new Intl.NumberFormat(this.location.locale).format(number);
  }

  setPaymentPlan(plan: paymentPlan) {
    const price = this.prices[this.location.currency][plan];
    const symbol = this.location.currency === CountryCurrency.rs ? 'Rs ' : '$';

    this.showDetails = true;
    if (plan === paymentPlan.monthly) {
      this.dropdownPlan = `Monthly - ${symbol}${this.formatPrice(price)} per month`;
      this.paymentText = `1 month x ${symbol}${this.formatPrice(price)}`;
      this.finalPrice = price;
      this.chosenPlan = 0;
    } else if (plan === paymentPlan.annual) {
      this.dropdownPlan = `Annual - ${symbol}${this.formatPrice(price)} per year`;
      this.paymentText = `1 year x ${symbol}${this.formatPrice(price)}`;
      this.finalPrice = price;
      this.chosenPlan = 1;
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  async handleForm(e) {
    e.preventDefault();
    this.confirmation = '';

    if (!this.userExists) {
      const userData = {
        email: e.target.email.value,
        firstName: e.target.first_name.value
      };

      if (this.validateEmail(userData.email) === false) {
        this.confirmation = 'Please enter a valid email.';
      } else if (userData.firstName === '') {
        this.confirmation = 'Please fill out all the fields';
      } else {
        let subscriptionExists = false;
        await this.user.checkIfSubscriptionWithEmailExists(userData.email).then((result) => {
          subscriptionExists = result;
        });

        if (subscriptionExists === false) {
          const paymentResult = await this.stripe.createPaymentMethod({
            type: 'card',
            card: this.card
          });

          let priceId: string;
          if (paymentResult.error) {
            // Inform the customer that there was an error.
            const cardErrors = paymentResult.error.message;
          } else {
            if (this.chosenPlan === 0) {
              priceId = environment.monthly_stripe_price[this.location.currency];
            } else if (this.chosenPlan === 1) {
              priceId = environment.annual_stripe_price[this.location.currency];
            } else {
              priceId = environment.lifetime_stripe_price;
            }
            // Send the token to your server.
            this.loading = true;
            const fun = this.functions.httpsCallable(environment.createSubscription);
            this.confirmation = await fun({
              userData: userData,
              paymentId: paymentResult.paymentMethod.id,
              priceId: priceId,
              coupon: this.stripeCouponCode,
              lifetime: this.chosenPlan === 2
            }).toPromise();

            if (this.confirmation === 'success') {
              await this.auth.sendSignInLink(userData.email);
              this.paymentSuccessful.emit(userData.email);
            }

            this.loading = false;
          }
        } else {
          this.paymentSuccessful.emit(userData.email);
        }
      }
    } else {
      const userData = {
        uid: this.appUser.personalInfo.uid,
        email: this.appUser.personalInfo.email
      };

      const paymentResult = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card
      });

      let priceId: string;
      if (paymentResult.error) {
        // Inform the customer that there was an error.
        const cardErrors = paymentResult.error.message;
      } else {
        if (this.chosenPlan === 0) {
          priceId = environment.monthly_stripe_price[this.location.currency];
        } else if (this.chosenPlan === 1) {
          priceId = environment.annual_stripe_price[this.location.currency];
        } else {
          priceId = environment.lifetime_stripe_price;
        }
        // Send the token to your server.
        this.loading = true;
        const fun = this.functions.httpsCallable(environment.createSubscription);
        this.confirmation = await fun({
          userData: userData,
          paymentId: paymentResult.paymentMethod.id,
          priceId: priceId,
          coupon: this.stripeCouponCode,
          lifetime: this.chosenPlan === 2
        }).toPromise();

        if (this.confirmation === 'success') {
          // await this.auth.sendSignInLink(userData.email);
          this.paymentSuccessful.emit('');
        }

        this.loading = false;
      }
    }
  }
}

export enum paymentPlan {
  monthly,
  annual,
  lifetime
}
