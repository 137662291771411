import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {PersonalInfo} from '../../../models/AppUser';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
  }

  resetPassword(u: PersonalInfo) {
    if (confirm('You are resetting your password. Check your email for a password reset link. Click confirm to continue.')) {
      this.authService.resetPassword(u.email);
      location.href = './login';
    }
  }
}
