import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {PaymentService} from './payment.service';
import {environment} from '../../../../environments/environment';
import {UserService} from '../../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'subscription-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentComponent implements OnInit {

  handler: any;
  hasCoupon: boolean;
  pricingPage = false;
  @Input('duration') duration: String;

  constructor(private router: Router, public pmt: PaymentService, private userService: UserService) { }

  ngOnInit() {
    const currentUrl = this.router.url;
    const x = currentUrl.split('/');
    x.forEach((element) => {
      if (element === 'pricing') {
        this.pricingPage = true;
      }
    });
  }

  cancelSubscription() {
    const finalQ = 'Are you sure you want to cancel your subscription to TheCodex?';
    if (confirm(finalQ)) {
      this.pmt.cancelSubscription();
    }
  }

  renewSubscription() {
    this.pmt.renewSubscription();
  }

  openHandler() {
    this.handler.open({
      name: 'TheCodex',
      excerpt: 'Pro Subscription',
      amount: 500
    });
  }

  openYearlyHandler() {
    this.handler.open({
      name: 'TheCodex',
      excerpt: 'MVP Subscription',
      amount: 5000
    });
  }

  showCoupon() {
    this.hasCoupon = true;
  }

  checkCoupon() {
    const el = (<HTMLInputElement>document.getElementById('couponCode'));
    if (el.value === 'admin19' || el.value === 'thecodexmvp' || el.value === 'codeon' || el.value === 'gobears') {
      const data = {};
      data['proMembership.status'] = 'active';
      data['proMembership.coupon'] = true;
      data['proMembership.couponText'] = el.value;
      this.userService.activateMembershipFromCoupon(data);
      this.hasCoupon = false;
    } else {
      alert('Whoops! That coupon code did not work. Please try again.');
    }
  }

}
