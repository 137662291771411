import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Course} from '../../../models/Course';
import {CourseService} from '../../../services/course.service';
import {CountryCurrency, LocationService} from '../../../services/location.service';
import {UserService} from '../../../services/user.service';
import '../../../../assets/js/drift.js';
import {Angulartics2Amplitude} from 'angulartics2/amplitude';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {
  @Input('pricingPage') onPricingPage = true;
  userSignedIn = true;
  courses: Course[];
  CountryCurrency = CountryCurrency;

  constructor(
    private location: LocationService,
    private userService: UserService,
    private angulartics2Amplitude: Angulartics2Amplitude,
    private titleService: Title,
    private metaService: Meta
  ) {
    angulartics2Amplitude.pageTrack('pricing');

    this.titleService.setTitle('Pricing - TheCodex');
    this.metaService.updateTag({
      name: 'description',
      content: 'This page lists our Pricing and Plans to join TheCodex.'
    });
  }

  ngOnInit() {}
}
