import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-signup-modal-button',
  templateUrl: './signup-modal-button.component.html',
  styleUrls: ['./signup-modal-button.component.css']
})
export class SignupModalButtonComponent implements OnInit {

  @Input() buttonText = 'Sign Up For Free';
  success = false;
  userEmail = '';
  @Input ('showWhite') showWhite: boolean;

  constructor(private modalService: NgbModal) {
  }

  ngOnInit(): void {
  }

  openVerticallyCentered(content) {
    this.modalService.open(content, {centered: true});
  }

  showSuccessLogin(email): void {
    this.success = true;
    this.userEmail = email;
  }
}
