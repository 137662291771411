import {Component, OnInit, Input} from '@angular/core';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Input('header') header;
  @Input('subheader') subheader;
  @Input('button') button;
  @Input('enroll') enroll;
  @Input('course') course;

  constructor(private us: UserService) {

  }

  ngOnInit() {
  }

  buttonClick() {
    if (this.enroll) {
      if (!this.us.user) {
        location.href = 'signup';
      }

      if (this.us.getPayingStatus()) {
        if (Object.keys(this.us.user.courses).includes(this.course.courseID)) {
          location.href = 'courses/' + this.course.courseID + '/landing-page';
        } else {
          this.us.enrollInCourse(this.course);
        }
      } else {
        location.href = 'pricing';
      }
    }
  }
}
