import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';
import {Lecture} from '../../../models/Lecture';
import Player from '@vimeo/player';
import {UserService} from '../../../services/user.service';
import {AppUser, Calendar} from '../../../models/AppUser';
import {userStatus} from '../../container-courses/coursecurriculum/coursecurriculum.component';
import {Content} from '../../../models/Content';
import {ContentService, ContentType} from '../../../services/content.service';
import {Angulartics2Amplitude} from 'angulartics2/amplitude';

@Component({
  selector: 'app-lectures',
  templateUrl: './lectures.component.html',
  styleUrls: ['./lectures.component.css']
})
export class LecturesComponent implements OnInit, OnDestroy {

  content: Content;
  lectureId: number;
  lectureIndex = 0;
  sectionIndex = 0;
  viewedLectures: number[] = [];
  amountOfVideoViewed = new Set();
  calendar: Calendar;
  lecture: Lecture;
  player: Player;
  loaded = false;
  user: AppUser;
  currentStatus = userStatus.fullAccess;
  startTime: number;

  signupOverlay = false;
  paymentOverlay = false;

  contentType: ContentType;

  constructor(private route: ActivatedRoute, private cs: ContentService, public sanitizer: DomSanitizer,
              private location: Location, private userService: UserService, private router: Router, private angulartics2Amplitude: Angulartics2Amplitude) {

    this.loaded = false;
    this.route.paramMap
      .subscribe(params => {
        // console.log(params);
        const contentType = params.get('contentType');
        if (contentType === 'courses') {
          this.contentType = ContentType.course;
          this.cs.updateContentCollection(ContentType.course);
        } else {
          this.contentType = ContentType.project;
          this.cs.updateContentCollection(ContentType.project);
        }

        const id = params.get('contentID');
        angulartics2Amplitude.pageTrack('lectures-' + id);

        this.sectionIndex = this.toInteger(params.get('sectionID'));
        this.lectureId = this.toInteger(params.get('lectureID'));
        this.cs.contentDetails(id).subscribe((c) => {
          this.content = c;
          this.cs.contentSections(id).subscribe((sections) => {
            this.content.sections = sections;

            this.userService.currentUser$.subscribe(appUser => {
              this.user = appUser;
              // tslint:disable-next-line:max-line-length
              this.lectureIndex = this.content.sections[this.sectionIndex].lectures.findIndex(x => x.videoID.toString() === this.lectureId.toString());
              this.updateButtonStyles();
              if (appUser) {
                if (appUser.proMembership.status !== 'inactive' || this.content.free) {
                  this.currentStatus = userStatus.fullAccess;
                } else {
                  this.currentStatus = userStatus.restricted;
                }
                this.loadPlayer();
                this.loadUserCalendar();
                this.loadViewedLectures();
              } else {
                this.loadPlayer();
                this.currentStatus = userStatus.noAuth;
              }
            });
          });
        });
      });
  }

  ngOnInit() {
    const v = document.getElementById('videoFrame');
    const c = document.getElementById('courseCurriculum');
    if (window.innerWidth > 480) {
      c.style.height = String(v.offsetHeight) + 'px';
    }

    this.startTime = Math.floor(Date.now() / 1000);

  }

  ngOnDestroy() {
    console.log('leaving');
    const endTime = Math.floor(Date.now() / 1000);
    this.angulartics2Amplitude.eventTrack('watched-lectures', {'duration-spent': endTime - this.startTime, 'content': this.content.ID});
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event) {
    this.PostCall();
  }

  PostCall() {
    console.log('leaving');
    const endTime = Math.floor(Date.now() / 1000);
    this.angulartics2Amplitude.eventTrack('watched-lectures', {'duration-spent': endTime - this.startTime, 'content': this.content.ID});
  }

  // Bootstrap removed this util function, copied from https://github.com/ng-bootstrap/ng-bootstrap/blob/master/src/util/util.ts
  toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }

  // THIS FUNCTION IS ONLY CALLED ONCE (BY NGONINIT)
  loadPlayer() {
    if (!this.loaded) {
      // alert(this.loaded);
      const fr = document.getElementById('vid');
      this.player = new Player(fr);
      this.player.unload();
      this.setupEndFunction();
      this.loaded = true;
    }
  }

  onVideoEnd(that) {
    this.player.off('ended');
    this.player.off('timeupdate');
    let curID = that.content.sections[this.sectionIndex].lectures[this.lectureIndex].videoID;
    if (typeof curID === 'string') {
      curID = parseInt(curID, 10);
    }
    setTimeout(() => {
      this.incrementLecture(1);
      // alert(curID);
      this.userService.updateContentProgress(this.lectureIndex, this.sectionIndex, this.content.ID, curID, this.contentType);
      if (this.viewedLectures.indexOf(curID) === -1) {
        this.viewedLectures.push(curID);
      }
      this.viewedLectures.push(curID);
      this.setupEndFunction();
    }, 500);
  }

  setupEndFunction() {
    const that = this;
    this.loadVideoInPlayer().then((res) => {
      this.player.on('timeupdate', function (e) {
        that.amountOfVideoViewed.add(Math.round(e.seconds));
        // console.log(Math.round(e.seconds));
        // console.log(that.amountOfVideoViewed);
        if (that.amountOfVideoViewed.size === Math.round(e.duration / 10)) {
          const increment = Math.round(e.duration / 10);
          const currentDate = that.getCurrentDate();
          // console.log(that.calendar);
          if (that.calendar && that.lectureId in that.calendar) {
            that.calendar[that.lectureId].durationWatched += increment;
          } else {
            that.calendar[that.lectureId] = {
              durationWatched: increment,
              name: that.content.sections[that.sectionIndex].lectures[that.lectureIndex].name,
              sectionName: that.content.sections[that.sectionIndex].name
            };
            // console.log(that.calendar);
          }

          that.userService.updateCalendar(currentDate, that.calendar, that.content.ID);
          that.amountOfVideoViewed = new Set();
        }
      });
      this.player.on('ended', function (e) {
        that.onVideoEnd(that);
      });
      this.player.pause();
    });
  }


  // Function called every time a new video is selected
  loadVideoInPlayer() {
    return new Promise<Boolean>((resolve, reject) => {
      const data = this.content.sections[this.sectionIndex].lectures[this.lectureIndex].videoID;
      this.amountOfVideoViewed = new Set();
      this.player.loadVideo(data).then(function (id) {
        resolve(true);
      });
    });
  }

  // Function called when user selects video from the sidebar
  updateVideoFromSidebar(lectureid, sectionid) {
    this.signupOverlay = false;
    this.paymentOverlay = false;
    this.lectureId = lectureid;
    this.sectionIndex = sectionid;
    // tslint:disable-next-line:max-line-length
    this.lectureIndex = this.content.sections[this.sectionIndex].lectures.findIndex(x => x.videoID.toString() === this.lectureId.toString());
    this.loadVideoInPlayer();
    this.updateButtonStyles();
  }

  showOverlaySignupView() {
    this.signupOverlay = true;
    this.paymentOverlay = false;
  }

  showOverlayPaymentView() {
    this.paymentOverlay = true;
    this.signupOverlay = false;
  }


  // Updating the buttons with the resepective color and disabled or not
  updateButtonStyles() {
    // const prev = <HTMLInputElement>document.getElementById('previous');
    // const next = <HTMLInputElement>document.getElementById('next');
    // prev.disabled = false;
    // next.disabled = false;
    // prev.style.backgroundColor = '#0191FE';
    // next.style.backgroundColor = '#0191FE';
    // if (this.lectureIndex === 0 && this.sectionIndex === 0) {
    //   prev.disabled = true;
    //   prev.style.backgroundColor = '#708090';
    // tslint:disable-next-line:max-line-length
    // } else if (this.lectureIndex === this.lengthOfSection(this.sectionIndex) - 1 && this.sectionIndex === this.course.sections.length - 1) {
    //   next.disabled = true;
    //   next.style.backgroundColor = '#708090';
    // }

  }

  // Function called when user selects previous or next lecture buttons
  incrementLecture(increment) {
    this.updateIncrement(increment);

    if (this.currentStatus === userStatus.noAuth && (this.sectionIndex >= 1 || this.lectureIndex >= 1)) {
      this.showOverlaySignupView();
    } else if (this.currentStatus === userStatus.restricted && (this.sectionIndex >= 2 || this.lectureIndex >= 3)) {
      alert('show payment view');
      this.showOverlayPaymentView();
    } else {
      this.updateVideoScreen();
    }
  }

  updateIncrement(increment) {
    this.lectureIndex += increment;
    if (increment === -1) {
      if (this.lectureIndex === -1) {
        if (this.sectionIndex === 0) {
          this.lectureIndex += 1;
        } else {
          this.sectionIndex -= 1;
          this.lectureIndex = this.lengthOfSection(this.sectionIndex) - 1;
        }
      }
    }

    if (increment === 1) {

      if (this.lectureIndex === this.lengthOfSection(this.sectionIndex)) {
        if (this.sectionIndex === this.content.sections.length - 1) {
          this.lectureIndex -= 1;
        } else {
          this.sectionIndex += 1;
          this.lectureIndex = 0;
        }
      }
    }
    this.lectureId = this.content.sections[this.sectionIndex].lectures[this.lectureIndex].videoID;
  }

  // Function to update the entire view after button selection from above
  updateVideoScreen() {
    this.loadVideoInPlayer();
    this.updateButtonStyles();
    const url = this.getLectureURL();
    window.history.pushState({}, null, url);
    // this.router.navigate([url]);
  }

  // Function to update the href of the browser with the new lectureID
  getLectureURL() {
    return './' + this.contentType + '/' + this.content.ID + '/' + this.sectionIndex + '/lecture/' + this.lectureId;
  }

  // Function that returns the number of lectures in a specific section
  lengthOfSection(id) {
    return this.content.sections[id].lectures.length;
  }

  loadViewedLectures() {
    this.viewedLectures = Object.assign([], this.userService.getViewedLectures(this.content.ID, this.contentType));
  }

  getCurrentDate() {
    const today = new Date();
    let dd = String(today.getDate());
    let mm = String(today.getMonth() + 1);
    const yyyy = String(today.getFullYear());
    if (this.toInteger(dd) < 10) {
      dd = '0' + dd;
    }

    if (this.toInteger(mm) < 10) {
      mm = '0' + mm;
    }
    return mm + '-' + dd + '-' + yyyy;
  }

  loadUserCalendar() {
    this.userService.loadCalendar(this.getCurrentDate(), this.content.ID).then((val) => {
      if (val) {
        if (this.content.ID in val) {
          this.calendar = val[this.content.ID];
        }
        // console.log(this.calendar);
      } else {
        this.calendar = {};
      }
    });
  }

}

