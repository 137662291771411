import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {

  showCourses: boolean;

  constructor() { }

  ngOnInit(): void {
    this.showCourses = true;
    document.getElementById('content-courses').style.borderBottom = '3px solid #3B92F6';

  }

  changeBorderColor() {
    if (this.showCourses === true) {
      document.getElementById('content-courses').style.borderBottom = '3px solid #3B92F6';
      document.getElementById('content-projects').style.borderBottom = 'none';
    } else {
      document.getElementById('content-projects').style.borderBottom = '3px solid #3B92F6';
      document.getElementById('content-courses').style.borderBottom = 'none';
    }
  }

}
