import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectService} from '../../../services/project.service';
import {UserService} from '../../../services/user.service';
import {CurrentLecture} from '../../../models/Lecture';
import {Project} from '../../../models/Project';
import {ContentType} from '../../../services/content.service';

@Component({
  selector: 'app-projecthome',
  templateUrl: './projecthome.component.html',
  styleUrls: ['./projecthome.component.css']
})
export class ProjecthomeComponent implements OnInit {
  project: Project;
  collapse = false;
  viewedLectures = [1];
  lastLecture: CurrentLecture;
  percentCompleted: number;
  loadedUser = false;


  constructor(private route: ActivatedRoute, private router: Router, private ps: ProjectService, private userService: UserService) { }

  ngOnInit(): void {
    this.route.paramMap
      .subscribe(params => {
        // console.log(params);
        const id = params.get('projectid');
        this.ps.loadEntireProject(id).then(project => {
          this.project = project;
          this.userService.currentUser$.subscribe(appUser => {
            if (appUser) {
              this.updateLastLecture();
              console.log('hello');
              this.loadedUser = true;
              console.log(this.loadedUser);
            }
          });
        });
      });
  }

  checkLectureID(lecID: number) {
    this.viewedLectures = this.userService.getViewedProjectLectures(this.project.projectID);
    return this.viewedLectures.includes(lecID);
  }

  // This function is called once the user data has arrived
  updateLastLecture() {
    console.log(this.userService.user);
    this.lastLecture = this.userService.getLastProjectLecture(this.project);
    console.log(this.lastLecture);
    this.updateProgressBar();
  }

  goToLastLecture() {
    // tslint:disable-next-line:max-line-length
    location.href = '/projects/' + this.project.projectID + '/' + 0 + '/lecture/' + this.lastLecture.lecture.videoID;
  }

  goToLecture(sectionid: number, videoid: number) {
    location.href = '/projects/' + this.project.projectID + '/' + 0 + '/lecture/' + videoid;
  }

  updateProgressBar() {
    const userNum = this.userService.getCountOfViewedLectures(this.project.projectID, ContentType.project) - 1;
    this.percentCompleted = Math.round(userNum / this.project.sections[0].lectures.length * 100);
  }

}
