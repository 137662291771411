import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/auth';
import {PersonalInfo, AppUser} from '../models/AppUser';
import {UserService} from './user.service';
import {environment} from '../../environments/environment';
import * as firebase from 'firebase';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/observable/of';
import {Angulartics2Amplitude} from 'angulartics2/amplitude';
import {Angulartics2} from 'angulartics2';

@Injectable()
export class AuthService {
  user$: Observable<firebase.User>;
  authState: any = null;
  userExists: boolean;
  userUID;

  constructor(
    private afAuth: AngularFireAuth,
    public userService: UserService,
    public angulartics2Amplitude: Angulartics2Amplitude
  ) {
    afAuth.setPersistence('local');
    this.user$ = this.afAuth.authState;
  }

  get appUser$(): Observable<AppUser> {
    return this.user$.switchMap((user) => {
      if (user) {
        return this.userService.getUserDocument(user.uid);
      }

      return Observable.of(null);
    });
  }

  private createAnalyticsProfile(name, email, userCredentials) {
    this.angulartics2Amplitude.setUsername(userCredentials.user.uid);
    this.angulartics2Amplitude.setUserProperties({
      'new-sign-up': true
    });
    this.userService.createUserOnSignUp(email, name, userCredentials.user.uid);
  }

  private setAnalyticsProfile(userCredentials) {
    this.angulartics2Amplitude.setUsername(userCredentials.user.uid);
    this.angulartics2Amplitude.setUserProperties({
      'user-logged-in': true
    });
    this.userService.getUserDetails(userCredentials.user.uid);
  }

  async signUpWithPassword(name, email, password): Promise<string> {
    try {
      const result = await this.afAuth.createUserWithEmailAndPassword(email, password);
      this.createAnalyticsProfile(name, email, result);

      return 'success';
    } catch (error) {
      console.error(error);

      // See Firebase error codes: https://firebase.google.com/docs/reference/js/firebase.auth.Auth#error-codes_3
      switch (error.code) {
        case 'auth/email-already-in-use':
          return 'An account with this email already exists.';
        case 'auth/invalid-email':
          return 'The provided email is invalid.';
        case 'auth/weak-password':
          return 'Your password is too weak.';
        default:
          // TODO: Perhaps we should log this error externally?
          return 'Something went wrong. Please contact support if this persists.';
      }
    }
  }

  async loginWithPassword(email: string, password: string) {
    try {
      const result = await this.afAuth.signInWithEmailAndPassword(email, password);
      this.setAnalyticsProfile(result);

      return 'success';
    } catch (error) {
      console.error(error);

      // See Firebase error codes: https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signinwithemailandpassword
      switch (error.code) {
        case 'auth/invalid-email':
        case 'auth/wrong-password':
          return 'The provided email or password is incorrect.';
        default:
          // TODO: Perhaps we should log this error externally?
          return 'Something went wrong. Please contact support if this persists.';
      }
    }
  }

  async getSignInMethods(email) {
    try {
      return this.afAuth.fetchSignInMethodsForEmail(email);
    } catch (error) {
      console.error(error);
      // TODO: Perhaps we should log this error externally?
      return [];
    }
  }

  // TODO: remove
  async sendSignInLink(email) {
    const actionCodeSettings = {
      url: environment.host + 'dashboard?mode=signIn',
      handleCodeInApp: true
    };

    return await this.afAuth
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(function () {
        window.localStorage.setItem('emailForSignIn', email);
        return 'success';
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  // TODO: remove
  async confirmSignIn(url) {
    if (this.authState === null) {
      try {
        if (this.afAuth.isSignInWithEmailLink(url)) {
          let email = window.localStorage.getItem('emailForSignIn');
          let name = window.localStorage.getItem('nameForSignIn');
          if (!email) {
            email = window.prompt('Please provide your email for confirmation');
            name = window.prompt('Please provide your first name for confirmation');
          }

          await this.afAuth.signInWithEmailLink(email, url).then((result) => {
            // Create either User Document or Subscription
            this.angulartics2Amplitude.setUsername(result.user.uid);
            if (result.additionalUserInfo.isNewUser) {
              this.angulartics2Amplitude.setUserProperties({
                'new-sign-up': true
              });
              this.userService.createUserOnSignUp(email, name, result.user.uid);
            } else {
              this.angulartics2Amplitude.setUserProperties({
                'user-logged-in': true
              });
              this.userService.getUserDetails(result.user.uid);
            }
          });
          // window.localStorage.removeItem('emailForSignIn');
        }
      } catch (err) {
        // alert('The Sign In Link seems to have expired. Please Log In again and request a new email to be sent to your inbox.');
        console.log(err);
      }
    }
  }

  // TODO: remove
  signup(u: PersonalInfo) {
    this.afAuth
      .createUserWithEmailAndPassword(u.email, u.password)
      .then((value) => {
        u.uid = value.user.uid;
        this.userService.createUserDocument(u);
      })
      .catch((err) => {
        // console.log('Something went wrong:', err.message);
      });
  }

  // TODO: remove
  login(email: string, password: string) {
    this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((value) => {
        // console.log('Nice, it worked!');
        location.href = '/courses';
      })
      .catch((err) => {
        // console.log('Something went wrong:', err.message);
        alert('Incorrect password or email - please try again');
      });
  }

  logout() {
    this.afAuth.signOut();
    this.userExists = false;
    document.location.href = './';
  }

  // Only called if the user is logged in
  getUID() {
    return this.userUID;
  }

  async resetPassword(email: string): Promise<boolean> {
    try {
      await this.afAuth.sendPasswordResetEmail(email);
      return true;
    } catch (error) {
      console.error(error);
      // TODO: Perhaps we should log this error externally?
      return false;
    }
  }
}
