// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // stripeKey: 'pk_live_Zjyxv4Ku9pMjfRtPv9vmLs5W',
  host: 'http://localhost:3000/',
  stripeKey: 'pk_test_w3nWdK7n6pjdjHd7C9tIHitr',
  createSubscription: 'stripeCreateCustomerAndSubscriptionDev',

  monthly_stripe_price: {
    usd: 'price_1H9548DULhgqhbyBcmuPexmM',
    rs: 'price_1HM3XCDULhgqhbyB7rqUrhei'
  },

  annual_stripe_price: {
    usd: 'price_1Hcf0iDULhgqhbyBJEw0gyoU',
    rs: 'price_1HM3XODULhgqhbyBZWOZXv5b'
  },

  lifetime_stripe_price: 'price_1H6jIrDULhgqhbyBMETew1yx',

  discounts: {
    ANNIVERSARY5: [
      ['LuLbUf0j', 10],
      ['H0t1xBYF', 60]
    ],
    STUDENTSPECIAL: [
      ['1xhStNcP', 0],
      ['1xhStNcP', 20]
    ],
    INDIADISCOUNT: [
      ['vZDVAHDJ', 0],
      ['vZDVAHDJ', 1000]
    ],
    MVPFOREVER: [
      ['77C19d59', 0],
      ['77C19d59', 99]
    ],
    HACKYEAR: [
      ['c4LftEZl', 0],
      ['c4LftEZl', 99]
    ]
  },

  amplitudeKey: 'b64459bd90ebca7c35d509f2a709aa6a',

  firebaseConfig: {
    apiKey: 'AIzaSyCACdVBsuBhVmVN7-GDFd5sTadrHJZyfZ4',
    authDomain: 'thecodex-1.firebaseapp.com',
    databaseURL: 'https://thecodex-1.firebaseio.com',
    projectId: 'thecodex-1',
    storageBucket: 'thecodex-1.appspot.com',
    messagingSenderId: '127479735603'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
