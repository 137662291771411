import { Component, OnInit } from '@angular/core';
import {ProjectService} from '../../../services/project.service';
import {Project} from '../../../models/Project';
import {Section} from '../../../models/Section';

@Component({
  selector: 'app-upload-projects',
  templateUrl: './upload-projects.component.html',
  styleUrls: ['./upload-projects.component.css']
})
export class UploadProjectsComponent implements OnInit {

  project: Project;
  showUpload: boolean;
  software: String;

  constructor(private projectService: ProjectService) {
  }

  ngOnInit(): void {
    this.loadProject('sentiment-analysis-of-stock-news-python');
    this.showUpload = false;
  }

  swapViews() {
    this.showUpload = !this.showUpload;
  }

  loadProject(projectID) {
    this.projectService.loadEntireProject(projectID).then(val => {
      this.project = val;
      console.log(this.project);
      if (!this.project.topics) {
        this.project.topics = ['', '', '', '', '', '', '', 'And more!'];
      }
    });
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  addLecture(index) {
    this.project.sections[index].lectures.push({name: '', videoID: null});
  }

  addSection() {
    const blankLec = {name: '', videoID: null};
    const blankSec: Section = ({name: '', lectures: [blankLec]});
    if (this.project.sections === null || this.project.sections === undefined) {
      this.project.sections = [blankSec];
    } else {
      this.project.sections.push(blankSec);
    }
  }

  updateProject() {
    alert('Updating project');
    this.projectService.updateProjectSections(this.project.projectID, this.project.sections);
  }

  updateProjectInfo(projectID) {
    this.project.software = this.software.split(',');
    const p = this.project;
    delete p.sections;
    console.log(p);
    this.projectService.updateProjectInfo(projectID, p);
  }

  deleteLecture(sectionIndex, lectureIndex) {
    this.project.sections[sectionIndex].lectures.splice(lectureIndex, 1);
  }

  deleteSection(sectionIndex) {
    this.project.sections.splice(sectionIndex, 1);
  }
}
