import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {AppUser} from '../../../models/AppUser';
import {AuthService} from '../../../services/auth.service';
import {Class} from '../../../models/Class';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  user: AppUser;
  class: Class;
  hasClassID: boolean;

  constructor(private userService: UserService, private authService: AuthService) {
    this.authService.appUser$.subscribe(appUser => {
      this.user = appUser;
      this.hasClassID = false;

    });
  }

  ngOnInit() {

  }


  updateUser() {
    // console.log(this.user.personalInfo);
    this.userService.updateUserProfile(this.user);
  }

  resetPassword() {
    this.authService.resetPassword(this.user.personalInfo.email);
    alert('An email has been sent to reset your password!');
  }

  logoutUser() {
    this.authService.logout();
  }
}
