import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {Injectable} from '@angular/core';
import {Message} from '../models/AppUser';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  messagesRef: AngularFirestoreCollection<Message>;

  constructor(private afs: AngularFirestore) {
    this.messagesRef = this.afs.collection<Message>('messages');
  }

  addMessage(m: Message) {
    this.messagesRef.add(m);
  }
}
