import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import {ProjectService} from '../../../services/project.service';
import {Project} from '../../../models/Project';
import {Router} from '@angular/router';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {

  @Input('solidBg') solidBg = false;
  @Input('index') index: number;
  @Input('projectsHeader') projectHeader = 'Featured Projects';
  landingPage: boolean;

  projects: Observable<Project[]>;

  constructor(private ps: ProjectService, private router: Router) {
    this.projects = ps.Projects.map((data) => {
      data.sort((a, b) => {
        return a.order < b.order ? -1 : 1;
      });
      return data;
    });
  }

  ngOnInit(): void {
    if (this.router.url === '/') {
      this.landingPage = true;
    }
  }

}
