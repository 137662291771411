import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginModalComponent implements OnInit {
  @Output() passwordEmailSent: EventEmitter<any> = new EventEmitter<any>();
  @Output() loginSuccessful: EventEmitter<any> = new EventEmitter<any>();

  loading = false;
  stage: 1 | 2 = 1;

  redMessage: string = null;

  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit(): void {}

  private isEmailValid(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase())) {
      this.redMessage = 'Please enter a valid email.';
      return false;
    }

    return true;
  }

  async handleForm(e) {
    e.preventDefault();
    this.loading = true;

    const email = e.target.email.value;
    const password = e.target.password ? e.target.password.value : null;

    if (this.isEmailValid(email)) {
      const signInMethods = await this.auth.getSignInMethods(email);

      if (signInMethods.length === 0) {
        this.redMessage = 'An account with this email does not exist.';
      } else if (signInMethods.includes('password')) {
        switch (this.stage) {
          case 1:
            this.stage = 2;
            this.redMessage = null;
            break;

          case 2:
            const authMessage = await this.auth.loginWithPassword(email, password);

            if (authMessage === 'success') {
              this.loginSuccessful.emit();
              this.router.navigate(['/dashboard']);
            } else {
              this.redMessage = authMessage;
            }
            break;
        }
      } else {
        this.auth.resetPassword(email);
        this.passwordEmailSent.emit(email);
      }
    }

    this.loading = false;
  }
}
