import {Component, OnInit} from '@angular/core';
import {YoutubeService} from '../../../services/youtube.service';
import {YoutubePlaylist} from '../../../models/YoutubePlaylist';
import {ActivatedRoute} from '@angular/router';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-youtube-watch',
  templateUrl: './youtube-watch.component.html',
  styleUrls: ['./youtube-watch.component.css']
})
export class YoutubeWatchComponent implements OnInit {

  playlistID: string;
  videoID: number;
  playlist: YoutubePlaylist;
  url: SafeResourceUrl;

  constructor(private youtubeService: YoutubeService, private route: ActivatedRoute, private sanitizer: DomSanitizer) {
    const url = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/SkVDfaHQwRU');
    this.url = url;
  }

  ngOnInit() {
    this.route.paramMap
      .subscribe(params => {
        this.playlistID = params.get('playlistid');
        this.videoID = this.toInteger(params.get('videoid'));

        this.youtubeService.loadEntirePlaylist(this.playlistID).then((val) => {
          this.playlist = val;
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.updateURL(this.playlist.urls[this.videoID]));
        });
      });

  }

  // Bootstrap removed this util function, copied from https://github.com/ng-bootstrap/ng-bootstrap/blob/master/src/util/util.ts
  toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }

  updateURL(url) {
    return url.replace('watch?v=', 'embed/') + '?rel=0';
  }

  updateVideoID(index) {
    this.videoID = index;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.updateURL(this.playlist.urls[this.videoID]));
  }

}
