import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Content} from '../../../models/Content';
import {AppUser} from '../../../models/AppUser';
import {Observable} from 'rxjs/Observable';
import {CurrentLecture} from '../../../models/Lecture';
import {ActivatedRoute, Router} from '@angular/router';
import {ContentService, ContentType} from '../../../services/content.service';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-dashboard-content',
  templateUrl: './dashboard-content.component.html',
  styleUrls: ['./dashboard-content.component.css']
})
export class DashboardContentComponent implements OnInit {

  @Input('showBlank') showBlank = false;
  @Input('content') content: Content;
  @Input('contentType') contentType: ContentType;
  @Output() completedcontent: EventEmitter<any> = new EventEmitter();

  user: AppUser;
  contents: Observable<Content[]>;
  collapse = false;
  lastLecture: CurrentLecture;
  percentCompleted: number;
  loadedUser = false;
  contentLink: string;
  startText: string;
  ContentType = ContentType;

  constructor(private route: ActivatedRoute, private router: Router, private cs: ContentService, private userService: UserService) {
  }

  ngOnInit() {
    if (this.showBlank) {
      this.contentLink = '/catalog';
      this.startText = 'View Catalog';
    } else {
      this.cs.updateContentCollection(this.contentType);
      this.cs.contentSections(this.content.ID).subscribe((sections) => {
        this.content.sections = sections;
        this.updateLastLecture();
        this.goToLastLecture();
        this.loadedUser = true;
      });
    }
  }

  // This function is called once the user data has arrived
  updateLastLecture() {
    this.lastLecture = this.userService.getLastLecture(this.content, this.contentType);
    this.updateProgressBar();
  }

  goToLastLecture() {
    this.contentLink = '/' + this.contentType + '/' + this.content.ID + '/' + this.lastLecture.sectionIndex + '/lecture/' + this.lastLecture.lecture.videoID;
  }

  updateProgressBar() {
    const userNum = this.userService.getCountOfViewedLectures(this.content.ID, this.contentType) - 1;
    this.percentCompleted = Math.round((userNum / this.content.lectures) * 100);

    if (this.percentCompleted === 100) {
      this.completedcontent.emit(true);
    }

    if (this.percentCompleted === 0) {
      this.startText = 'Start';
    } else {
      this.startText = 'Resume';
    }
  }
}
