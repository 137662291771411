import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {environment} from '../environments/environment';
import {AppComponent} from './app.component';
import {AngularFireModule} from '@angular/fire/';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireFunctionsModule} from '@angular/fire/functions';
import {CoursesComponent} from './components/container-courses/courses/courses.component';
import {CoursecardComponent} from './components/container-courses/coursecard/coursecard.component';
import {NavbarComponent, navStyle} from './components/common-components/navbar/navbar.component';
import {HeaderComponent} from './components/common-components/header/header.component';
import {PurposeComponent} from './components/container-landing/purpose/purpose.component';
import {ImagebarComponent} from './components/container-landing/imagebar/imagebar.component';
import {SignupComponent} from './components/container-auth/signup/signup.component';
import {LandingPageComponent} from './components/container-landing/landing-page/landing-page.component';
import {AuthService} from './services/auth.service';
import {UserService} from './services/user.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CoursedetailComponent} from './components/container-courses/coursedetail/coursedetail.component';
import {CourseService} from './services/course.service';
import {LecturesComponent} from './components/container-learning/lectures/lectures.component';
import {CoursecurriculumComponent} from './components/container-courses/coursecurriculum/coursecurriculum.component';
import {CoursehomeComponent} from './components/container-learning/coursehome/coursehome.component';
import {DashboardComponent} from './components/container-user/dashboard/dashboard.component';
import {LoginComponent} from './components/container-auth/login/login.component';
import {PricingComponent} from './components/container-pricing/pricing/pricing.component';
import {PaymentComponent} from './components/container-pricing/payments/payment.component';
import {DatabasePopulateComponent} from './components/internal/database-populate/database-populate.component';
import {AboutComponent} from './components/container-landing/about/about.component';
import {ContactComponent} from './components/container-landing/contact/contact.component';
import {TeacherdashboardComponent} from './components/container-teacher/teacherdashboard/teacherdashboard.component';
import {ProfileComponent} from './components/container-user/profile/profile.component';
import {TeacherAnalyticsComponent} from './components/container-teacher/teacher-analytics/teacher-analytics.component';
import {TestimonialsComponent} from './components/container-landing/testimonials/testimonials.component';
import {UploadYoutubeComponent} from './components/internal/upload-youtube/upload-youtube.component';
import {YoutubeComponent} from './components/container-youtube/youtube/youtube.component';
import {YoutubeWatchComponent} from './components/container-youtube/youtube-watch/youtube-watch.component';
import {ForgotPasswordComponent} from './components/container-auth/forgotpassword/forgotpassword.component';
import {HttpClientModule} from '@angular/common/http';
import {MissionComponent} from './components/container-landing/mission/mission.component';
import {HowItWorksComponent} from './components/container-landing/how-it-works/how-it-works.component';
import {PricingCardComponent} from './components/container-pricing/pricing-card/pricing-card.component';
import {FooterComponent} from './components/common-components/footer/footer.component';
import {CoursedetailcardComponent} from './components/container-courses/coursedetailcard/coursedetailcard.component';
import {FaqComponent} from './components/common-components/faq/faq.component';
import {PricingPageComponent} from './components/container-pricing/pricing-page/pricing-page.component';
import {PricingConversionComponent} from './components/container-pricing/pricing-conversion/pricing-conversion.component';
import {PaymentModalComponent} from './components/container-pricing/payment-modal/payment-modal.component';
import {PaymentCheckoutComponent} from './components/container-pricing/payment-checkout/payment-checkout.component';
import {CatalogPageComponent} from './components/container-courses/catalog-page/catalog-page.component';
import {LoginModalComponent} from './components/container-auth/login-modal/login-modal.component';
import {VideoButtonComponent} from './components/common-components/video-button/video-button.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ProjectcardComponent} from './components/container-projects/projectcard/projectcard.component';
import {ProjectDetailComponent} from './components/container-projects/project-detail/project-detail.component';
import {ProjectCatalogComponent} from './components/container-projects/project-catalog/project-catalog.component';
import {ProjectsComponent} from './components/container-projects/projects/projects.component';
import {ProjecthomeComponent} from './components/container-learning/projecthome/projecthome.component';
import {LectureOverlayComponent} from './components/container-learning/lecture-overlay/lecture-overlay.component';
import {DashboardContentComponent} from './components/container-user/dashboard-content/dashboard-content.component';
import {SignupModalComponent} from './components/container-auth/signup-modal/signup-modal.component';
import {ForgotPasswordModalComponent} from './components/container-auth/forgotpassword-modal/forgotpassword-modal.component';
import {ContentComponent} from './components/container-landing/content/content.component';
import {MeetInstructorComponent} from './components/container-landing/meet-instructor/meet-instructor.component';
import {Angulartics2Module} from 'angulartics2';
import {UploadProjectsComponent} from './components/internal/upload-projects/upload-projects.component';
import {SignupModalButtonComponent} from './components/container-auth/signup-modal-button/signup-modal-button.component';
import {CheckoutComponent} from './components/container-pricing/checkout/checkout.component';
import {LandingAnalyticsComponent} from './components/container-landing/landing-analytics/landing-analytics.component';
import {UploadExercisesComponent} from './components/internal/upload-exercises/upload-exercises.component';

@NgModule({
  declarations: [
    AppComponent,
    CoursesComponent,
    CoursecardComponent,
    NavbarComponent,
    HeaderComponent,
    PurposeComponent,
    ImagebarComponent,
    SignupComponent,
    LandingPageComponent,
    CoursedetailComponent,
    LecturesComponent,
    CoursecurriculumComponent,
    CoursehomeComponent,
    DashboardComponent,
    LoginComponent,
    PricingComponent,
    PaymentComponent,
    DatabasePopulateComponent,
    AboutComponent,
    ContactComponent,
    TeacherdashboardComponent,
    ProfileComponent,
    TeacherAnalyticsComponent,
    TestimonialsComponent,
    UploadYoutubeComponent,
    YoutubeComponent,
    YoutubeWatchComponent,
    ForgotPasswordComponent,
    HowItWorksComponent,
    MissionComponent,
    PricingCardComponent,
    FooterComponent,
    CoursedetailcardComponent,
    FaqComponent,
    PricingPageComponent,
    PricingConversionComponent,
    PaymentModalComponent,
    PaymentCheckoutComponent,
    CatalogPageComponent,
    LoginModalComponent,
    VideoButtonComponent,
    ProjectcardComponent,
    ProjectDetailComponent,
    ProjectCatalogComponent,
    ProjectsComponent,
    ProjecthomeComponent,
    LectureOverlayComponent,
    DashboardContentComponent,
    SignupModalComponent,
    ForgotPasswordModalComponent,
    ContentComponent,
    MeetInstructorComponent,
    UploadProjectsComponent,
    SignupModalButtonComponent,
    CheckoutComponent,
    LandingAnalyticsComponent,
    UploadExercisesComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireFunctionsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    Angulartics2Module.forRoot(),
    FormsModule,
    NgbModule,
    HttpClientModule,
    FontAwesomeModule,
    RouterModule.forRoot(
      [
        {path: '', component: LandingPageComponent, data: {navAttr: navStyle.blueTextWhiteBg}},
        {path: 'about', component: AboutComponent, data: {navAttr: navStyle.whiteTextTransparentBg}},
        {path: 'contact', component: ContactComponent, data: {navAttr: navStyle.whiteTextTransparentBg}},
        {path: 'pricing', component: PricingPageComponent, data: {navAttr: navStyle.blueTextWhiteBg}},
        {path: 'profile', component: ProfileComponent, data: {navAttr: navStyle.whiteTextTransparentBg}},
        {path: 'dashboard', component: DashboardComponent, data: {navAttr: navStyle.blueTextWhiteBg}},

        {path: 'python-bootcamp', component: LandingPageComponent, data: {navAttr: navStyle.blueTextWhiteBg}},
        {path: 'courses/:courseid', component: CoursedetailComponent, data: {navAttr: navStyle.whiteTextTransparentBg}},
        {
          path: 'courses/:courseid/landing-page',
          component: CoursehomeComponent,
          data: {navAttr: navStyle.blueTextWhiteBg}
        },
        // tslint:disable-next-line:max-line-length
        {
          path: ':contentType/:contentID/:sectionID/lecture/:lectureID',
          component: LecturesComponent,
          data: {navAttr: navStyle.whiteTextBlackBg}
        },
        {path: 'catalog', component: CatalogPageComponent, data: {navAttr: navStyle.whiteTextTransparentBg}},
        {
          path: 'projects/:projectid',
          component: ProjectDetailComponent,
          data: {navAttr: navStyle.whiteTextTransparentBg}
        },
        {
          path: 'projects/:projectid/landing-page',
          component: ProjecthomeComponent,
          data: {navAttr: navStyle.blueTextWhiteBg}
        },
        // {path: 'upload-projects', component: UploadProjectsComponent, data: {navAttr: navStyle.blueTextWhiteBg}},
        // {path: 'upload-courses', component: DatabasePopulateComponent, data: {navAttr: navStyle.blueTextWhiteBg}},
        {path: 'checkout', component: CheckoutComponent},
        {path: 'upload-exercises', component: UploadExercisesComponent, data: {navAttr: navStyle.blueTextWhiteBg}}
        // {path: 'signup', component: SignupComponent},
        // {path: 'login', component: LoginComponent},
        // {path: 'teacher-dashboard', component: TeacherdashboardComponent},
        // {path: 'teacher-dashboard/:classid', component: TeacherAnalyticsComponent},
        // {path: 'projects/:projectid/0/lecture/:lectureid', component: LecturesComponent, data: {navAttr: navStyle.blueTextWhiteBg}},
        // {path: 'upload-youtube', component: UploadYoutubeComponent},
        // {path: 'youtube-videos', component: YoutubeComponent},
        // {path: 'youtube-videos/:playlistid/:videoid', component: YoutubeWatchComponent},
        // {path: 'forgot-password', component: ForgotPasswordComponent}
      ],
      {
        scrollPositionRestoration: 'enabled' // Add options right here
      }
    ),
    FontAwesomeModule
  ],
  providers: [AuthService, UserService, CourseService],
  bootstrap: [AppComponent]
})
export class AppModule {}
