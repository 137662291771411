import {AfterViewInit, Component, OnInit} from '@angular/core';
import '../../../../assets/js/drift.js';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {Angulartics2Amplitude} from 'angulartics2/amplitude';

@Component({
  selector: 'app-landing',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  constructor(private auth: AuthService, private router: Router, angulartics2Amplitude: Angulartics2Amplitude) {
    angulartics2Amplitude.pageTrack('landing');
  }

  ngOnInit() {
  }

}
