import {Component, Input, OnInit} from '@angular/core';
import {Project} from '../../../models/Project';


@Component({
  selector: 'app-projectcard',
  templateUrl: './projectcard.component.html',
  styleUrls: ['./projectcard.component.css']
})
export class ProjectcardComponent implements OnInit {

  @Input('home') home: Boolean;
  @Input('project') project: Project;
  @Input('url') url: string;

  constructor() { }

  ngOnInit(): void {
  }

}
