import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {Observable} from 'rxjs/Observable';
import {Section} from '../models/Section';
import {Course} from '../models/Course';


@Injectable({
  providedIn: 'root'
})
export class CourseService {

  courseCollection: AngularFirestoreCollection<Course>;
  courses: Observable<Course[]>;
  c: Observable<Course>;
  sections: Observable<Section[]>;
  course: Course;

  constructor(private afs: AngularFirestore) {
    this.courseCollection = this.afs.collection('courses');
  }

  // TODO: Update all of these requests such that the subscription is also done in the service, and it just returns the value

  get Courses() {
    this.courses = this.courseCollection.valueChanges();
    return this.courses;
  }

  courseDetails(id) {
    this.c = this.afs.collection('courses').doc(id).valueChanges() as Observable<Course>;
    return (this.c);
  }

  courseSections(courseID) {
    this.sections = this.afs.collection('courses').doc(courseID).collection('sections').valueChanges();
    return this.sections;
  }

  loadEntireCourse(courseID) {
    return new Promise<Course>((resolve, reject) => {
      this.afs.collection('courses').doc(courseID).valueChanges().subscribe(data => {
        this.course = data as Course;
      });
      this.afs.collection('courses').doc(courseID).collection('sections').valueChanges().subscribe(data => {
        this.course.sections = data;
        resolve(this.course);
      });
    });
  }

  updateCourseSections(courseID, sections) {
    sections.forEach((element, key) => {
      this.afs.collection('courses').doc(courseID).collection('sections').doc('section' + key).set(element);
    });
  }

  updateCourseInfo(courseID, course) {
    this.afs.collection('courses').doc(courseID).update(course);
  }
}
