import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {Course} from '../models/Course';
import {Observable} from 'rxjs/Observable';
import {Section} from '../models/Section';
import {Content} from '../models/Content';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  contentCollection: AngularFirestoreCollection<Content>;
  contents: Observable<Content[]>;
  c: Observable<Content>;
  sections: Observable<Section[]>;
  content: Content;

  constructor(private afs: AngularFirestore) {

  }

  updateContentCollection(contentType: ContentType) {
    this.contentCollection = this.afs.collection(contentType);
  }

  // TODO: Update all of these requests such that the subscription is also done in the service, and it just returns the value
  contentsOf(contentType) {
    this.updateContentCollection(contentType);
    return this.afs.collection(contentType).valueChanges();
  }

  get Contents() {
    this.contents = this.contentCollection.valueChanges();
    return this.contents;
  }

  contentDetails(id) {
    this.c = this.contentCollection.doc(id).valueChanges() as Observable<Content>;
    return (this.c);
  }

  contentSections(courseID) {
    this.sections = this.contentCollection.doc(courseID).collection('sections').valueChanges();
    return this.sections;
  }

  loadEntireContent(ID) {
    return new Promise<Content>((resolve, reject) => {
      this.contentCollection.doc(ID).valueChanges().subscribe(data => {
        this.content = data as Content;
      });
      this.contentCollection.doc(ID).collection('sections').valueChanges().subscribe(data => {
        this.content.sections = data;
        resolve(this.content);
      });
    });
  }

  updateContentSections(ID, sections) {
    sections.forEach((element, key) => {
      this.contentCollection.doc(ID).collection('sections').doc('section' + key).set(element);
    });
  }

  updateContentInfo(ID, content) {
    this.contentCollection.doc(ID).update(content);
  }
}

export enum ContentType {
  course = 'courses',
  project = 'projects'
}
