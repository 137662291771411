import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {Injectable} from '@angular/core';
import {Message} from '../models/AppUser';

export enum CountryCurrency {
  usd = 'usd',
  rs = 'rs'
}

@Injectable({providedIn: 'root'})
export class LocationService {
  country: string = null;
  currency: CountryCurrency.usd | CountryCurrency.rs = CountryCurrency.usd;
  readonly locale = 'en-US';

  constructor() {
    this.getCountry();
  }

  async getCountry() {
    const response = await fetch('https://ipinfo.io/json');
    this.country = (await response.json()).country;

    // Check if the user is connecting from India
    if (this.country === 'IN') {
      this.currency = CountryCurrency.rs;
    }
  }
}
