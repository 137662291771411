import {AppUser, Calendar, CourseCalendar, PersonalInfo, UserCourse, UserProject} from '../models/AppUser';
import {BehaviorSubject, Observable} from 'rxjs';
import {AngularFirestore, AngularFirestoreCollection, DocumentChangeAction} from '@angular/fire/firestore';
import {Injectable} from '@angular/core';
import {Course} from '../models/Course';
import {Project} from '../models/Project';
import {map} from 'rxjs/operators';
import {CurrentLecture} from '../models/Lecture';
import {Class} from '../models/Class';
import {Subscription} from '../models/Subscription';
import {Content} from '../models/Content';
import {ContentType} from './content.service';
import {Angulartics2Amplitude} from 'angulartics2/amplitude';


@Injectable()
export class UserService {
  currentUser$: BehaviorSubject<AppUser> = new BehaviorSubject<AppUser>(null);
  currentUser: AppUser;
  usersRef: AngularFirestoreCollection<AppUser>;
  classesRef: AngularFirestoreCollection<Class>;
  subscriptionsRef: AngularFirestoreCollection<Subscription>;

  constructor(private afs: AngularFirestore, private angulartics2Amplitude: Angulartics2Amplitude) {
    this.usersRef = this.afs.collection<AppUser>('users');
    this.classesRef = this.afs.collection<Class>('classes');
    this.subscriptionsRef = this.afs.collection<Subscription>('subscriptions');
  }

  set user(user) {
    this.currentUser = user;
  }

  setCurrentUser(user) {
    this.currentUser$.next(user);
  }

  getCurrentUser$(): Observable<AppUser> {
    return this.currentUser$.asObservable();
  }

  get user() {
    return this.currentUser;
  }

  createUserDocument(u: PersonalInfo) {
    const data = {};

    this.usersRef.doc('' + u.uid).set({
      personalInfo:
        {
          firstName: u.firstName,
          lastName: u.lastName,
          email: u.email,
          uid: u.uid,
          onboarded: false
        },
      proMembership: {
        status: 'inactive',
        token: 'none'
      }
    }).then(val => {
      this.getUserDetails(u.uid);
      window.location.href = '/';
    });
  }

  createUserOnSignUp(email, name, uid) {
    this.subscriptionsRef.doc(email).ref.get().then((doc) => {
      // console.log(doc);
      if (doc.exists !== false) {
        const subscriptionData = doc.data();
        subscriptionData['personalInfo']['uid'] = uid;
        this.usersRef.doc(uid).set({
          'personalInfo': subscriptionData['personalInfo'],
          'proMembership': subscriptionData['proMembership']
        }).then(val => {
          doc.ref.delete();
          this.getUserDetails(uid);
        });
      } else {
        this.usersRef.doc(uid).set({
          personalInfo:
            {
              firstName: name,
              lastName: '',
              email: email,
              uid: uid
            },
          proMembership: {
            status: 'inactive',
            token: 'none'
          }
        }).then(val => {
          this.getUserDetails(uid);
        });
      }
    });
  }

  getUserDetails(uid: string) {
    return new Promise<Boolean>((resolve, reject) => {
      this.getUserDocument(uid).subscribe(userData => {
        this.currentUser = userData;
      });

      this.loadUserCourseData(uid).then(res => {
        this.loadUserProjectData(uid).then(projectRes => {
          this.setCurrentUser(this.currentUser);
          resolve(true);
        });
      });
    });
  }

  updateAnalytics(experienceAnswer: number, interestAnswer: number, reasonsAnswer: Array<boolean>) {
    this.currentUser.analytics = this.currentUser.analytics || {onboarded: true};
    this.usersRef.doc(this.currentUser.personalInfo.uid).update({
      analytics:
        {
          experience: experienceAnswer,
          interest: interestAnswer,
          reasons: reasonsAnswer,
          onboarded: true,
        }
    }).then(val => {
    });
  }

  getUserDocument(uid: string) {
    return this.usersRef.doc(uid).valueChanges() as Observable<AppUser>;
  }

  loadUserCourseData(uid: string) {
    return new Promise<Boolean>((resolve, reject) => {
      const courseRef = this.afs.collection<UserCourse>('users/' + uid + '/courses');
      const courseData = courseRef.snapshotChanges()
        .pipe(
          map((actions: DocumentChangeAction<UserCourse>[]) => {
            // console.log('hi');
            return actions.map((a: DocumentChangeAction<UserCourse>) => {
              const data: Object = a.payload.doc.data() as UserCourse;
              const id = a.payload.doc.id;
              const fin = {};
              fin[id] = data;
              return fin;
            });
          }),
        );

      let finalCourseData = {};
      courseData.subscribe(val => {
        // console.log(val);
        val.forEach((element) => {
          finalCourseData = Object.assign({}, finalCourseData, element);
        });
        this.currentUser.courses = finalCourseData;
        resolve(true);
      });
    });
  }

  enrollInCourse(course: Course) {
    // console.log(this.user.courses[course.courseID]);
    this.angulartics2Amplitude.setUserProperties({'enroll-course': course.courseID});
    const data = {enrolled: true, currentLecture: 0, currentSection: 0, watchedLectures: [1]};
    this.usersRef.doc('' + this.user.personalInfo.uid).collection('courses').doc(course.courseID)
      .set(data).then(val => {
      location.href = '/dashboard#courses';
    });
  }

  loadUserProjectData(uid: string) {
    return new Promise<Boolean>((resolve, reject) => {
      const projectRef = this.afs.collection<UserProject>('users/' + uid + '/projects');
      const projectData = projectRef.snapshotChanges()
        .pipe(
          map((actions: DocumentChangeAction<UserProject>[]) => {
            return actions.map((a: DocumentChangeAction<UserProject>) => {
              const data: Object = a.payload.doc.data() as UserProject;
              const id = a.payload.doc.id;
              const fin = {};
              fin[id] = data;
              return fin;
            });
          }),
        );

      let finalProjectData = {};
      projectData.subscribe(val => {
        val.forEach((element) => {
          finalProjectData = Object.assign({}, finalProjectData, element);
        });
        this.user.projects = finalProjectData;
        resolve(true);
      });
    });
  }

  enrollInProject(project: Project) {
    this.angulartics2Amplitude.setUserProperties({'enroll-project': project.projectID});
    const data = {enrolled: true, currentLecture: 0, currentSection: 0, watchedLectures: [1]};
    this.usersRef.doc('' + this.user.personalInfo.uid).collection('projects').doc(project.projectID)
      .set(data).then(val => {
      location.href = '/dashboard#projects';
    });
  }

  updateProjectProgress(curLec: number, curSec: number, projectID: string, lecID: number) {
    const wLec = this.user.projects[projectID].watchedLectures;
    // console.log(wLec);
    if (wLec.indexOf(lecID) > -1) {
      // console.log('already watched lecture');
    } else {
      wLec.push(lecID);
      this.usersRef.doc(this.user.personalInfo.uid).collection('projects').doc(projectID).update({
        currentLecture: curLec,
        currentSection: curSec,
        watchedLectures: wLec
      });
    }
  }

  loadAllStudentsCalendarData(classID: string) {
    return this.afs.collection<AppUser>('users', ref => ref.where('classID',
      '==', classID).orderBy('personalInfo.firstName', 'asc')).valueChanges();
  }

  loadAllCalendarData(uid: String) {
    const courseRef = this.afs.collection<CourseCalendar>('users/' + uid + '/calendar');
    return new Promise<Object>((resolve, reject) => {
      const courseData = courseRef.snapshotChanges()
        .pipe(
          map((actions: DocumentChangeAction<CourseCalendar>[]) => {
            // console.log('hi');
            return actions.map((a: DocumentChangeAction<CourseCalendar>) => {
              const data: Object = a.payload.doc.data() as CourseCalendar;
              const id = a.payload.doc.id;
              const fin = {};
              fin[id] = data;
              return fin;
            });
          }),
        );

      let finalCourseData = {};
      courseData.subscribe(val => {
        // console.log(val);
        val.forEach((element) => {
          finalCourseData = Object.assign({}, finalCourseData, element);
        });
        resolve(finalCourseData);
      });
    });
  }

  loadCalendar(day: string, courseID: string) {
    return new Promise<CourseCalendar>((resolve, reject) => {
      this.usersRef.doc(this.user.personalInfo.uid).collection('calendar').doc(day)
        .valueChanges().subscribe(data => {
        resolve(data as CourseCalendar);
      });
    });
  }

  updateCalendar(day: string, calendar: Calendar, ID: string) {
    const data = {};
    data[ID] = calendar;
    // this.angulartics2Amplitude.setUserProperties({'watched-content': {ID: calendar}});
    this.usersRef.doc(this.user.personalInfo.uid).collection('calendar').doc(day).set(data, {merge: true});
  }

  activateMembershipFromCoupon(data) {
    this.usersRef.doc(this.user.personalInfo.uid).update(data);
  }

  updateContentProgress(curLec: number, curSec: number, ID: string, lecID: number, contentType: ContentType) {
    let wLec = [];
    if (contentType === ContentType.course) {
      wLec = this.user.courses[ID].watchedLectures;
    } else {
      wLec = this.user.projects[ID].watchedLectures;
    }
    // console.log(wLec);
    if (wLec.indexOf(lecID) > -1) {
      // console.log('already watched lecture');
    } else {
      wLec.push(lecID);
      this.angulartics2Amplitude.setUserProperties({'watched-video': lecID});
      this.usersRef.doc(this.user.personalInfo.uid).collection(contentType).doc(ID).update({
        currentLecture: curLec,
        currentSection: curSec,
        watchedLectures: wLec
      });
    }
  }

  getViewedLectures(ID: string, contentType: ContentType) {
    // console.log('got lectures');
    if (contentType === ContentType.course) {
      return this.user.courses[ID].watchedLectures;
    } else {
      return this.user.projects[ID].watchedLectures;
    }
  }

  getCountOfViewedLectures(ID: string, type: ContentType) {
    if (type === ContentType.course) {
      return this.user.courses[ID].watchedLectures.length;
    } else {
      return this.user.projects[ID].watchedLectures.length;
    }
  }

  getLastLecture(content: Content, type: ContentType) {
    let lectureData = {};
    if (type === ContentType.course) {
      lectureData = [this.user.courses[content.ID].currentSection, this.user.courses[content.ID].currentLecture];
    } else {
      lectureData = [this.user.projects[content.ID].currentSection, this.user.projects[content.ID].currentLecture];
    }
    const curLec: CurrentLecture = {lecture: content.sections[lectureData[0]].lectures[lectureData[1]], sectionIndex: lectureData[0]};
    return curLec;
  }

  getViewedProjectLectures(projectID: string) {
    return this.user.projects[projectID].watchedLectures;
  }

  getCountOfViewedProjectLectures(projectID: string) {
    return this.user.projects[projectID].watchedLectures.length;
  }

  getLastProjectLecture(project: Project) {
    const curLec: CurrentLecture = {lecture: project.sections[0].lectures[this.user.projects[project.projectID].currentLecture]};
    return curLec;
  }

  getPayingStatus() {
    return this.user.proMembership.status === 'active' || this.user.proMembership.status === 'ending';
  }

  updateUserProfile(user: AppUser) {
    this.usersRef.doc(user.personalInfo.uid).update({personalInfo: user.personalInfo});

  }

  calculateUserStreak() {
    return this.usersRef.doc(this.user.personalInfo.uid).collection('calendar').get();
  }

  updateUserClass(user: AppUser) {
    return new Promise<Class>((resolve, reject) => {
      this.classesRef.doc('' + user.classID).valueChanges().subscribe((val) => {
        if (val == null) {
          alert('The Class ID you entered is invalid. Please check with your teacher again.');
          resolve(null);
        } else {
          this.usersRef.doc(user.personalInfo.uid).update({classID: user.classID});
          resolve(<Class>val);
        }
      });
    });
  }

  getClass(classID: string) {
    return new Promise<Class>((resolve, reject) => {
      this.classesRef.doc('' + classID).valueChanges().subscribe((val) => {
        if (val) {
          resolve(<Class>val);
        } else {
          resolve(null);
        }
      });
    });
  }

  deleteUserClass(user: AppUser) {
    this.usersRef.doc(user.personalInfo.uid).update({classID: null});
  }

  createClass(cl: Class, user: AppUser) {
    this.classesRef.doc(cl.classID).set(cl);
    if (this.user.classesTeaching) {
      this.user.classesTeaching.push(cl.classID);
    } else {
      this.user.classesTeaching = [cl.classID];
    }
    this.usersRef.doc(user.personalInfo.uid).update({classesTeaching: user.classesTeaching}).then((val) => {
      location.href = '/teacher-dashboard/' + cl.classID;
    });
  }

  updateClass(cl: Class) {
    this.classesRef.doc(cl.classID).update(cl);
    alert('Your Class has been updated!');
  }

  deleteClass(cl: Class, uid: string) {
    this.classesRef.doc(cl.classID).delete().then((val) => {
      const ind = this.user.classesTeaching.indexOf(cl.classID);
      this.user.classesTeaching.splice(ind, 1);
      this.usersRef.doc(uid).update({classesTeaching: this.user.classesTeaching});
      alert('Your Class has been deleted!');
      location.href = '/teacher-dashboard/';
    });
  }

  async checkIfSubscriptionWithEmailExists(email) {
    return new Promise<boolean>((resolve, reject) => {
      this.subscriptionsRef.doc(email).ref.get().then((doc) => {
        resolve(doc.exists === true);
      });
    });
  }

}
