import {Component, Input, OnInit, Optional} from '@angular/core';
import {Location} from '@angular/common';
import {LecturesComponent} from '../../container-learning/lectures/lectures.component';
import {faCheckCircle, faLock, faVideo} from '@fortawesome/free-solid-svg-icons';
import {Content} from '../../../models/Content';
import {ContentType} from '../../../services/content.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-coursecurriculum',
  templateUrl: './coursecurriculum.component.html',
  styleUrls: ['./coursecurriculum.component.css']
})
export class CoursecurriculumComponent implements OnInit {

  @Input('content') content: Content;
  @Input('contentType') contentType: ContentType;
  @Input('viewedLectures') viewedLectures: number[];
  @Input('selectedSection') chosenSection: number;
  @Input('selectedLecture') chosenLectureId: string;
  @Input('userStatus') currentStatus: userStatus;


  faVideo = faVideo;
  faCheck = faCheckCircle;
  faLock = faLock;
  private userStatus = userStatus;

  maxSectionIndex = 2;
  maxLectureIndex = 3;

  constructor(private location: Location, @Optional() private lectureView: LecturesComponent, private router: Router) {

  }

  ngOnInit() {
    if (this.contentType === ContentType.project) {
      this.maxLectureIndex = 1;
    }
  }


  pushNewVideo(lectureid, sectionIndex, lectureIndex, url) {
    // console.log(this.currentStatus);
    if (this.currentStatus === userStatus.noAuth && (sectionIndex > 0 || lectureIndex > 0)) {
      this.lectureView.showOverlaySignupView();
    } else if (this.currentStatus === userStatus.restricted && (sectionIndex >= this.maxSectionIndex || lectureIndex >= this.maxLectureIndex)) {
      this.lectureView.showOverlayPaymentView();
    } else {
      this.chosenSection = sectionIndex;
      this.chosenLectureId = lectureid;
      this.lectureView.updateVideoFromSidebar(lectureid, sectionIndex);
      history.pushState(null, '', url);
      // this.router.navigate([url]);
    }
  }

}

export enum userStatus {
  fullAccess,
  restricted,
  noAuth
}
