import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {Observable} from 'rxjs/Observable';
import {Section} from '../models/Section';
import {Project} from '../models/Project';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  projectCollection: AngularFirestoreCollection<Project>;
  projects: Observable<Project[]>;
  p: Observable<Project>;
  sections: Observable<Section[]>;
  project: Project;

  constructor(private afs: AngularFirestore) {
    this.projectCollection = this.afs.collection('projects');
  }

  get Projects() {
    this.projects = this.projectCollection.valueChanges();
    return this.projects;
  }
  projectDetails(id) {
    this.p = this.afs.collection('projects').doc(id).valueChanges() as Observable<Project>;
    return (this.p);
  }

  projectSections(projectID) {
    this.sections = this.afs.collection('projects').doc(projectID).collection('sections').valueChanges();
    return this.sections;
  }

  loadEntireProject(projectID) {
    return new Promise<Project>((resolve, reject) => {
      this.afs.collection('projects').doc(projectID).valueChanges().subscribe(data => {
        this.project = data as Project;
      });
      this.afs.collection('projects').doc(projectID).collection('sections').valueChanges().subscribe(data => {
        this.project.sections = data;
        resolve(this.project);
      });
    });
  }

  updateProjectSections(projectID, sections) {
    sections.forEach((element, key) => {
      this.afs.collection('projects').doc(projectID).collection('sections').doc('section' + key).set(element);
    });
  }

  updateProjectInfo(projectID, project) {
    console.log(project);
    console.log(projectID);
    this.afs.collection('projects').doc(projectID).update(project);
  }
}
