import {Component, OnInit, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CourseService} from '../../../services/course.service';
import {Course} from '../../../models/Course';
import {AuthService} from '../../../services/auth.service';
import {AppUser} from '../../../models/AppUser';
import {Angulartics2Amplitude} from 'angulartics2/amplitude';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-coursedetail',
  templateUrl: './coursedetail.component.html',
  styleUrls: ['./coursedetail.component.css']
})
export class CoursedetailComponent implements OnInit {
  course: Course;
  appUser: AppUser;
  loadedAuth = false;

  constructor(
    private route: ActivatedRoute,
    private cs: CourseService,
    private auth: AuthService,
    private angulartics2Amplitude: Angulartics2Amplitude,
    private titleService: Title,
    private metaService: Meta
  ) {
    auth.appUser$.subscribe((appUser) => {
      this.appUser = appUser;
      this.loadedAuth = true;
    });
  }

  @Input('index') index: number;

  userLoaded;
  loadButton = false;
  buttonText: string;

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      // console.log(params);
      const id = params.get('courseid');
      this.angulartics2Amplitude.pageTrack('course-detail-' + id);

      this.cs.courseDetails(id).subscribe((course) => {
        this.course = course;

        // Standard meta
        this.titleService.setTitle(this.course.title + ' - TheCodex');
        this.metaService.updateTag({
          name: 'description',
          content: this.course.subtitle
        });
        this.metaService.updateTag({
          name: 'image',
          content: this.course.imgURL
        });

        // OpenGraph meta
        this.metaService.updateTag({
          name: 'og:title',
          content: this.course.title
        });
        this.metaService.updateTag({
          name: 'og:description',
          content: this.course.subtitle
        });
        this.metaService.updateTag({
          name: 'og:image',
          content: this.course.imgURL
        });

        // Twitter meta
        this.metaService.updateTag({
          name: 'twitter:title',
          content: this.course.title
        });
        this.metaService.updateTag({
          name: 'twitter:description',
          content: this.course.subtitle
        });
        this.metaService.updateTag({
          name: 'twitter:image',
          content: this.course.imgURL
        });

        this.cs.courseSections(id).subscribe((sections) => {
          course.sections = sections;
        });
      });
    });
  }
}
