import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {userStatus} from '../../container-courses/coursecurriculum/coursecurriculum.component';
import {AppUser} from '../../../models/AppUser';
import {Angulartics2Amplitude} from 'angulartics2/amplitude';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  user: AppUser;
  success = false;
  paymentEmail = '';
  accountExists = false;

  @ViewChild('success') templateRef: TemplateRef<any>;


  constructor(private modalService: NgbModal, private userService: UserService, private router: Router, private route: ActivatedRoute, private angulartics2Amplitude: Angulartics2Amplitude) {
    this.userService.getCurrentUser$().subscribe(appUser => {
      this.user = appUser;
    });
  }

  ngOnInit(): void {

  }

  showSuccessModal(paymentEmail): void {
    if (paymentEmail === '') {
      this.accountExists = true;
    } else {
      this.paymentEmail = paymentEmail;
    }
    this.angulartics2Amplitude.setUserProperties({'payment-status': 'true'});
    this.success = true;
    this.openVerticallyCentered(this.templateRef);
  }

  closeModal(modal) {
    if (this.accountExists) {
      location.href = '/dashboard';
    } else {
      modal.close();
    }
  }

  openVerticallyCentered(content) {

    if (this.user) {
      this.modalService.open(content, {centered: true, backdrop: 'static', keyboard: false});
    } else {
      this.modalService.open(content, {centered: true});
    }
  }
}
