import {Component, ElementRef, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../../services/user.service';
import {AuthService} from '../../../services/auth.service';
import {AppUser} from '../../../models/AppUser';
import {Router} from '@angular/router';
import {userStatus} from '../../container-courses/coursecurriculum/coursecurriculum.component';
import {Angulartics2Amplitude} from 'angulartics2/amplitude';


declare var Stripe;

@Component({
    selector: 'app-payment-modal',
    templateUrl: './payment-modal.component.html',
    styleUrls: ['./payment-modal.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class PaymentModalComponent implements OnInit {

    @Input('button-class') buttonClass: string;
    @Input('navbar-class') navbarClass = 'navbar-blue';
    @Input('button-text') buttonText = 'Get Pro Access';
    @Input('buttonEnroll') buttonEnroll = false;
    @Input('buttonProjectEnroll') buttonProjectEnroll = false;
    @Input('course') course;
    @Input('project') project;
    @Input('paymentButton') paymentButton: false;
    @Input('showMonthly') showMonthly = false;
    @Input('lifetimeButton') lifetimeButton = false;


    user: AppUser;
    model = 1;
    success = false;
    paymentEmail = '';
    enrollButtonFunc = this.goToEnrollCourse;
    currentStatus: userStatus = null;
    accountExists = false;
    paymentPlan = null;

    // tslint:disable-next-line:max-line-length
    constructor(private modalService: NgbModal, private userService: UserService, private authService: AuthService, private router: Router, private angulartics2Amplitude: Angulartics2Amplitude) {
    }



    showSuccessModal(paymentEmail): void {
        if (paymentEmail === '') {
            this.accountExists = true;
            this.userService.getUserDetails(this.user.personalInfo.uid);
        } else {
            this.paymentEmail = paymentEmail;
        }
        this.angulartics2Amplitude.setUserProperties({'paymentStatus': 'true'});
        this.success = true;
    }

    closeModal(modal) {
        if (this.accountExists) {
            location.href = '/dashboard';
        } else {
            modal.close();
        }
    }

    openVerticallyCentered(content) {
        if (this.lifetimeButton === true) {
            this.model = 3;
        } else {
            if (this.showMonthly === true) {
                this.model = 1;
            } else {
                this.model = 2;
            }
        }

        if (this.user) {
            this.modalService.open(content, {centered: true, backdrop: 'static', keyboard: false});
        } else {
            this.modalService.open(content, {centered: true});
        }
    }

    ngOnInit(): void {
        this.userService.getCurrentUser$().subscribe(appUser => {
            this.user = appUser;
            if (appUser) {
                if (appUser.proMembership.status !== 'inactive') {
                    this.currentStatus = userStatus.fullAccess;
                } else {
                    this.currentStatus = userStatus.restricted;
                }
            } else {
                this.currentStatus = userStatus.noAuth;
            }
            // console.log(this.project);
            this.onUserLoad();
        });
    }

    onUserLoad() {
        // console.log(this.project);
        if (!this.paymentButton) {
            if (this.isCoursesPage()) {
                if (this.currentStatus === userStatus.noAuth) {
                    this.enrollButtonFunc = this.goToCourseLecture;
                } else {
                    this.buttonEnroll = true;
                    if (Object.keys(this.userService.user.courses).includes(this.course.courseID)) {
                        this.buttonText = 'Go to Course Home';
                        this.enrollButtonFunc = this.goToCourseHome;
                    } else {
                        this.buttonText = 'Enroll in Course';
                        this.enrollButtonFunc = this.goToEnrollCourse;
                    }
                }
            } else if (this.isProjectsPage()) {
                if (this.currentStatus === userStatus.noAuth) {
                    this.enrollButtonFunc = this.goToProjectLecture;
                } else {
                    this.buttonEnroll = true;
                    // console.log(this.user.projects);
                    if (this.user.projects !== undefined && Object.keys(this.user.projects).includes(this.project.projectID)) {
                        this.buttonText = 'Go to Project Home';
                        this.enrollButtonFunc = this.goToProjectHome;
                    } else {
                        this.buttonText = 'Enroll in Project';
                        this.enrollButtonFunc = this.goToEnrollProject;
                    }
                }
            }
        }
    }

    isCoursesPage() {
        return this.router.url.includes('/courses/');
    }

    isProjectsPage() {
        return this.router.url.includes('/projects');
    }

    goToCourseLecture() {
      this.angulartics2Amplitude.eventTrack('try-course', {'course': this.course.courseID});
      this.router.navigate(['/courses/' + this.course.courseID + '/0/lecture/' + this.course.sections[0].lectures[0].videoID]);
    }

    goToCourseHome() {
        this.router.navigate(['/dashboard'], {fragment: 'courses'});
        // this.router.navigate(['/courses/' + this.course.courseID + '/landing-page']);
    }

    goToEnrollCourse() {
        // this.angulartics2Amplitude.eventTrack('enroll-course', {'name': this.course.title});
      this.angulartics2Amplitude.eventTrack('enroll-course', {'course': this.course.courseID});
      this.userService.enrollInCourse(this.course);
    }

    goToProjectLecture() {
      this.angulartics2Amplitude.eventTrack('try-project', {'project': this.project.projectID});

      this.router.navigate(['/projects/' + this.project.projectID + '/0/lecture/' + this.project.sections[0].lectures[0].videoID]);

    }

    goToProjectHome() {
        this.router.navigate(['/dashboard'], {fragment: 'projects'});

        // location.href = 'projects/' + this.project.projectID + '/landing-page';
    }

    goToEnrollProject() {
      this.angulartics2Amplitude.eventTrack('enroll-project', {'project': this.project.projectID});
      this.userService.enrollInProject(this.project);
    }
}
