import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Course} from '../../../models/Course';
import {CourseService} from '../../../services/course.service';
import {UserService} from '../../../services/user.service';
import {AuthService} from '../../../services/auth.service';
import {AppUser} from '../../../models/AppUser';
import {ProjectService} from '../../../services/project.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Content} from '../../../models/Content';
import {ContentType} from '../../../services/content.service';
import {Angulartics2Amplitude} from 'angulartics2/amplitude';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import set = Reflect.set;


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {

  @ViewChild('analytics') templateRef: TemplateRef<any>;

  currentUser: AppUser;
  courses: Content[];
  projects: Content[];
  blankCourse: Course;
  blankProject: Content;
  loadedUserCourses = false;
  loadedUserProjects = false;
  currentDate = new Date();
  showCourses: boolean;
  streak = 0;
  coursesCompleted = 0;
  projectsCompleted = 0;
  ContentType = ContentType;
  showBlankCourse: boolean;
  showBlankProject: boolean;
  success = false;

  constructor(private cs: CourseService, private userService: UserService, private ps: ProjectService, private auth: AuthService,
              private router: Router, private route: ActivatedRoute, private angulartics2Amplitude: Angulartics2Amplitude,
              private modalService: NgbModal) {
    angulartics2Amplitude.pageTrack('dashboard');

    this.userService.getCurrentUser$().subscribe(appUser => {
      this.currentUser = appUser;
      if (appUser) {
        this.loadUserStreak();

        if (!appUser.analytics || !appUser.analytics.onboarded) {
          // setTimeout(() => this.openVerticallyCentered(this.templateRef), 1000);


        }
      }
      this.cs.Courses.subscribe(courseData => {
        this.courses = courseData as unknown as Content[];
        if (appUser) {
          this.loadUserCourses();
        }
        this.ps.Projects.subscribe(projectData => {
          this.projects = projectData as unknown as Content[];
          if (appUser) {
            this.loadUserProjects();
          }
        });
      });
    });
  }


  ngOnInit() {
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment === null || fragment === undefined) {
        this.showCourses = true;
      } else {
        this.showCourses = fragment === 'courses';
      }
    });

    this.showBlankProject = false;
    this.showBlankCourse = false;
    const url = this.router.url;
    if (url.includes('mode=signIn')) {
      this.auth.confirmSignIn(url);
    }

    this.blankCourse = {
      title: 'Add Courses to your dashboard.',
      subtitle: 'Head over to Catalog to enroll in more courses.',
      imgURL: 'https://firebasestorage.googleapis.com/v0/b/thecodex-1.appspot.com/o/Codex%20YouTube.jpg?alt=media&token=2bf0c35c-8739-4931-8191-cec831ec5ad2',
    };
    this.blankProject = {
      title: 'Add Projects to your dashboard.',
      subtitle: 'Head over to Catalog to enroll in more proejcts.',
      imgURL: 'https://firebasestorage.googleapis.com/v0/b/thecodex-1.appspot.com/o/Codex%20YouTube.jpg?alt=media&token=2bf0c35c-8739-4931-8191-cec831ec5ad2',
    };
  }

  openVerticallyCentered(content) {
    this.modalService.open(content, {centered: true});
    // @ts-ignore
    twttr.widgets.load();

  }

  closeModal() {
    this.modalService.dismissAll();
  }

  emailUser() {
    const email = (<HTMLInputElement>document.getElementById('share-email')).value;
    location.href = 'mailto:' + email + '?subject= Hey there - check out TheCodex!';
  }


  loadUserCourses() {
    const courseIds = Object.keys(this.userService.user.courses);
    if (courseIds.length === 0) {
      this.showBlankCourse = true;
    }
    this.courses = (this.courses as Content[]).filter(function (element, index, array) {
      return courseIds.includes(element.ID);
    });
    this.loadedUserCourses = true;
    this.courses.forEach(course => {
      const userNum = this.userService.getCountOfViewedLectures(course.ID, ContentType.course) - 1;
      const percentCompleted = Math.round((userNum / course.lectures) * 100);

      if (percentCompleted === 100) {
        this.coursesCompleted += 1;
      }
    });
  }

  loadUserProjects() {
    const projectIds = Object.keys(this.userService.user.projects);
    if (projectIds.length === 0) {
      this.showBlankProject = true;
    }
    this.projects = (this.projects as Content[]).filter(function (element, index, array) {
      return projectIds.includes(element.ID);
    });
    this.loadedUserProjects = true;
    this.projects.forEach(project => {
      const userNum = this.userService.getCountOfViewedLectures(project.ID, ContentType.project) - 1;
      const percentCompleted = Math.round((userNum / project.sections[0].lectures.length) * 100);

      if (percentCompleted === 100) {
        this.projectsCompleted += 1;
      }
    });
  }

  loadUserStreak() {
    this.userService.calculateUserStreak().subscribe(querySnapshot => {
      let dates = [];
      querySnapshot.forEach(doc => {
        dates.push(doc.id);
      });
      let count = 0;
      dates.reverse().forEach((el, i) => {
        if ((new Date().setUTCHours(0, 0, 0, 0) - new Date(el).setUTCHours(0, 0, 0, 0)) === i * 86400000) {
          count++;
        }
      });
      this.streak = count;
    });
  }

  copyLink() {
    const copyText = (<HTMLInputElement>document.getElementById('invitation_link'));
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand('copy');

    /* Alert the copied text */
    alert('Copied - Go share your link!');
  }

}
