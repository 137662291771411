import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire//firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import {AppUser} from '../../../models/AppUser';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  userId: string;
  membership: any;
  userRef: AngularFirestoreCollection<AppUser>;

  constructor(private authService: AngularFireAuth, private afs: AngularFirestore) {
    this.userRef = this.afs.collection<any>('users');
    this.authService.authState.subscribe((auth) => {
      if (auth) {
        this.userId = auth.uid;
        this.userRef.doc(this.userId).valueChanges().subscribe(data => {
          this.membership = data['proMembership'];
          // console.log(this.membership);
        });
      }
    });
  }

  cancelSubscription() {
    const data = {};
    data['proMembership.status'] = 'cancelling';
    this.userRef.doc(this.userId).update(data);
  }

  renewSubscription() {
    const data = {};
    data['proMembership.status'] = 'renewing';
    this.userRef.doc(this.userId).update(data);
  }

  processPayment(token: any, duration: String) {
    const data = {};
    data['proMembership.token'] = token.id;
    data['proMembership.status'] = 'joining';
    data['proMembership.duration'] = duration;
    this.userRef.doc(this.userId)
      .update(data);
  }
}
