import {Component, Input, OnInit} from '@angular/core';
import {Course} from '../../../models/Course';
import {CourseService} from '../../../services/course.service';
import {Observable} from 'rxjs/Observable';
import {Project} from '../../../models/Project';
import {ProjectService} from '../../../services/project.service';



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  @Input('largeFooter') largeFooter = false;
  @Input('lightBackground') lightBackground = false;
  courses: Observable<Course[]>;
  projects: Observable<Project[]>;
  public innerWidth: any;


  constructor(private cs: CourseService, private ps: ProjectService) {
    this.courses = cs.Courses.map((data) => {
      data.sort((a, b) => {
        return a.order < b.order ? -1 : 1;
      });
      return data;
    });
    this.projects = ps.Projects.map((data) => {
      for (var proj of data) {
        if (proj.order == null) {
          proj.order = data.length;
        }
      }
      data.sort((a, b) => {
        return a.order < b.order ? -1 : 1;
      });
      return data;
    });
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    // console.log(this.innerWidth);
  }

}
