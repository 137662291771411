import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AppUser} from '../../../models/AppUser';
import {Angulartics2Amplitude} from 'angulartics2/amplitude';
import {element} from 'protractor';

@Component({
  selector: 'app-landing-analytics',
  templateUrl: './landing-analytics.component.html',
  styleUrls: ['./landing-analytics.component.css']
})
export class LandingAnalyticsComponent implements OnInit {

  loading = false;

  appUser: AppUser;
  loadedAuth: boolean;
  confirmation = null;
  experienceAnswer: number;
  interestAnswer: number;
  experienceEnum = experienceEnum;
  interestEnum = interestEnum;
  reasonsAnswer: Array<boolean>;
  @Output() analyticsSuccessful: EventEmitter<any> = new EventEmitter<any>();


  constructor(private auth: AuthService, private router: Router, private userService: UserService, private modalService: NgbModal, private angulartics2Amplitude: Angulartics2Amplitude) {
    auth.appUser$.subscribe(appUser => {
      this.appUser = appUser;
      this.userService.user = this.appUser;
      this.loadedAuth = true;
    });
  }

  ngOnInit(): void {
    this.reasonsAnswer = [false, false, false];
  }

  sendResponses() {
    if (!this.experienceAnswer || !this.interestAnswer || !this.reasonsAnswer) {
      alert('We\'d love if you filled out all of our questions!');
    } else {
      this.userService.updateAnalytics(this.experienceAnswer, this.interestAnswer, this.reasonsAnswer);
      this.angulartics2Amplitude.eventTrack('analytics-onboard', {
        'experience': this.experienceAnswer,
        'interest': this.interestAnswer,
        'reason-job': (this.reasonsAnswer)[0],
        'reason-switch-career': (this.reasonsAnswer)[1],
        'reason-passion-project': (this.reasonsAnswer)[2]
      });
      this.analyticsSuccessful.emit(true);
    }
  }
}

export enum experienceEnum {
  none,
  little,
  alot,
}

export enum interestEnum {
  projects,
  courses,
  both
}

