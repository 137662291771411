import {Component, OnInit} from '@angular/core';
import {Message} from '../../../models/AppUser';
import {MessagesService} from '../../../services/messages.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  hasSubmitted: boolean;
  num1: number;
  num2: number;

  constructor(private mService: MessagesService) {
  }

  ngOnInit() {
    this.hasSubmitted = false;
    this.num1 = Math.floor(Math.random() * 6) + 1;
    this.num2 = Math.floor(Math.random() * 6) + 1;
  }

  sendEmail(e: Message) {
    if (e.sum !== (this.num1 + this.num2)) {
      alert('Incorrect sum please try again');
    } else {
      this.mService.addMessage(e);
      this.hasSubmitted = true;
    }
  }

}

