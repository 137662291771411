import {Component, Input, OnInit} from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-video-button',
  templateUrl: './video-button.component.html',
  styleUrls: ['./video-button.component.css']
})
export class VideoButtonComponent implements OnInit {
  @Input('videoId') videoId;
  @Input('previewButton') previewButton = false;
  videoLinkSafe: SafeResourceUrl;

  constructor(private modalService: NgbModal, public sanitizer: DomSanitizer) {
  }
  openVerticallyCentered(content) {
    this.modalService.open(content, {centered: true, size: 'xl'}).result.then((result) => {});

  }


  ngOnInit(): void {
    this.videoLinkSafe = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + this.videoId);
    // console.log(this.videoLinkSafe);
  }

}
