import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {PersonalInfo} from '../../../models/AppUser';
import {AuthService} from '../../../services/auth.service';
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SignupComponent implements OnInit {

  @Input() firstName: string;
  @Input() lastName: string;
  @Input() email: string;
  @Input() password: string;
  @Input() rePassword: string;

  constructor(public authService: AuthService, private http:HttpClient) {
  }

  ngOnInit() {
  }

  signUp(u: PersonalInfo) {
    if (u.password === u.confirmPassword) {
      this.authService.signup(u);
    } else {
      alert('The passwords don\'t match. Please try confirming your password again.');
    }

  }

}
