import { Component, OnInit } from '@angular/core';
import {ExerciseService} from '../../../services/exercise.service';
import {Exercise} from '../../../models/Exercise';
import {Section} from '../../../models/Section';

@Component({
  selector: 'app-upload-exercises',
  templateUrl: './upload-exercises.component.html',
  styleUrls: ['./upload-exercises.component.css']
})
export class UploadExercisesComponent implements OnInit {

  exercise: Exercise;
  showUpload: boolean;
  buttonClicked: boolean;

  constructor(private exerciseService: ExerciseService) { }

  ngOnInit(): void {
    this.loadExercise('wgk8kc');
    this.exercise.difficulty = 1;
  }


  loadExercise(exerciseID) {
    this.exerciseService.loadEntireExercise(exerciseID).then(val => {
      this.exercise = val;
      console.log(this.exercise.solution);
    });
  }

  updateExerciseInfo(exerciseID) {
    const e = this.exercise;
    e.description = e.description.replace(/\n\r?/g, '<br/>');
    console.log(e);
    this.exerciseService.updateExerciseInfo(exerciseID, e);
  }

  generateExercise() {
    this.exercise.exerciseID = this.exerciseService.createExercise;
    const exercise = (<HTMLInputElement>document.getElementById('exerciseID'));
    exercise.value = this.exercise.exerciseID;
    this.loadExercise(this.exercise.exerciseID);
    this.updateExerciseInfo(this.exercise.exerciseID);
  }
}


