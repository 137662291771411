import {Component, OnInit, Input} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import {CourseService} from '../../../services/course.service';
import {Course} from '../../../models/Course';
import {Router} from '@angular/router';



@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {

  @Input('solidBg') solidBg = false;
  @Input('index') index: number;
  @Input('coursesHeader') coursesHeader = 'Featured Courses';
  landingPage: boolean;

  courses: Observable<Course[]>;

  constructor(private cs: CourseService, private router: Router) {
    this.courses = cs.Courses.map((data) => {
      data.sort((a, b) => {
        return a.order < b.order ? -1 : 1;
      });
      return data;
    });
  }


  ngOnInit() {
    if (this.router.url === '/') {
      this.landingPage = true;
    }
  }

}
