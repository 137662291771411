import {Component, OnInit, Input} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-pricing-card',
  templateUrl: './pricing-card.component.html',
  styleUrls: ['./pricing-card.component.css']
})


export class PricingCardComponent implements OnInit {

  model = 1;
  @Input('landingPage') landingPage: boolean;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    if(this.router.url === '/') {
      this.landingPage = true;
    } else if (this.router.url === '/pricing') {
      this.landingPage = false;
    }
  }
}
