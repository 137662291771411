import {Component, OnInit} from '@angular/core';
import {Course} from '../../../models/Course';
import {Section} from '../../../models/Section';
import {YoutubePlaylist} from '../../../models/YoutubePlaylist';
import {YoutubeService} from '../../../services/youtube.service';

@Component({
  selector: 'app-upload-youtube',
  templateUrl: './upload-youtube.component.html',
  styleUrls: ['./upload-youtube.component.css']
})
export class UploadYoutubeComponent implements OnInit {

  playlist: YoutubePlaylist;
  playlistID: string;

  constructor(private youtubeService: YoutubeService) {
  }

  ngOnInit() {
    this.loadPlaylist('python-beginners');
  }


  loadPlaylist(playlistID) {
    this.playlistID = playlistID;
    this.youtubeService.loadEntirePlaylist(playlistID).then(val => {
      this.playlist = val;
      // console.log(this.playlist);
    });
  }

  addLecture() {
    this.playlist.titles.push('');
    this.playlist.descriptions.push('');
    this.playlist.urls.push('');
    this.playlist.thumbnails.push('');
  }

  updateCourse() {
    alert('Updating playlist');
    this.youtubeService.updatePlaylist(this.playlistID, this.playlist);
  }

  deleteVideo(videoIndex) {
    this.playlist.titles.splice(videoIndex, 1);
    this.playlist.descriptions.splice(videoIndex, 1);
    this.playlist.urls.splice(videoIndex, 1);
    this.playlist.thumbnails.splice(videoIndex, 1);

  }

}
