import { Component, OnInit } from '@angular/core';
import {ProjectService} from '../../../services/project.service';
import {Observable} from 'rxjs/Observable';
import {Project} from '../../../models/Project';

@Component({
  selector: 'app-project-catalog',
  templateUrl: './project-catalog.component.html',
  styleUrls: ['./project-catalog.component.css']
})
export class ProjectCatalogComponent implements OnInit {

  projects: Observable<Project[]>;

  constructor(private ps: ProjectService) {
    this.projects = ps.Projects.map((data) => {
      data.sort((a, b) => {
        return a.order < b.order ? -1 : 1;
        console.log(a.projectID);
        console.log(b.projectID);
      });
      return data;
    });
  }

  ngOnInit(): void {
  }

}
