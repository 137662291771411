import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-forgotpassword-modal',
  templateUrl: './forgotpassword-modal.component.html',
  styleUrls: ['./forgotpassword-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordModalComponent implements OnInit {
  @Output() resetEmailSent: EventEmitter<any> = new EventEmitter<any>();

  loading = false;

  redMessage: string = null;

  constructor(private auth: AuthService) {}

  ngOnInit(): void {}

  private isEmailValid(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase())) {
      this.redMessage = 'Please enter a valid email.';
      return false;
    }

    return true;
  }

  async handleForm(e) {
    e.preventDefault();
    this.loading = true;

    const email = e.target.email.value;

    if (this.isEmailValid(email)) {
      const signInMethods = await this.auth.getSignInMethods(email);

      if (signInMethods.length === 0) {
        this.redMessage = 'An account with this email does not exist.';
      } else {
        if (await this.auth.resetPassword(email)) {
          this.auth.resetPassword(email);
          this.resetEmailSent.emit(email);
        } else {
          this.redMessage = 'Something went wrong. Please try again later.';
        }
      }
    }

    this.loading = false;
  }
}
