import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ProjectService} from '../../../services/project.service';
import {Observable} from 'rxjs/Observable';
import {Project} from '../../../models/Project';
import {DomSanitizer, Meta, SafeResourceUrl, Title} from '@angular/platform-browser';
import {UserService} from '../../../services/user.service';
import {CourseService} from '../../../services/course.service';
import {AuthService} from '../../../services/auth.service';
import {AppUser} from '../../../models/AppUser';
import {Angulartics2Amplitude} from 'angulartics2/amplitude';
@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.css']
})
export class ProjectDetailComponent implements OnInit {
  appUser: AppUser;
  loadedAuth = false;
  project: Project;
  videoLinkSafe: SafeResourceUrl;
  freeIndex: number;
  project_flags = [
    '../../../../assets/img/projects-assets/projects-freeflag.png',
    '../../../../assets/img/projects-assets/projects-proflag.png'
  ];

  // tslint:disable-next-line:max-line-length
  constructor(
    private route: ActivatedRoute,
    private ps: ProjectService,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private auth: AuthService,
    private angulartics2Amplitude: Angulartics2Amplitude,
    private titleService: Title,
    private metaService: Meta
  ) {
    auth.appUser$.subscribe((appUser) => {
      this.appUser = appUser;
      this.loadedAuth = true;
    });
  }

  ngOnInit(): void {
    // console.log(this.userService.user);
    this.route.paramMap.subscribe((params) => {
      const id = params.get('projectid');
      this.angulartics2Amplitude.pageTrack('project-detail-' + id);

      this.ps.projectDetails(id).subscribe((project) => {
        this.project = project;

        // Standard meta
        this.titleService.setTitle(this.project.title + ' - TheCodex');
        this.metaService.updateTag({
          name: 'description',
          content: this.project.subtitle
        });
        this.metaService.updateTag({
          name: 'image',
          content: this.project.imgURL
        });

        // OpenGraph meta
        this.metaService.updateTag({
          property: 'og:title',
          content: this.project.title
        });
        this.metaService.updateTag({
          property: 'og:description',
          content: this.project.subtitle
        });
        this.metaService.updateTag({
          property: 'og:image',
          content: this.project.imgURL
        });

        // Twitter meta
        this.metaService.updateTag({
          name: 'twitter:title',
          content: this.project.title
        });
        this.metaService.updateTag({
          name: 'twitter:description',
          content: this.project.subtitle
        });
        this.metaService.updateTag({
          name: 'twitter:image',
          content: this.project.imgURL
        });

        this.videoLinkSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.project.gifURL);
        if (!this.project.free) {
          this.freeIndex = 1;
        } else {
          this.freeIndex = 0;
        }
        this.ps.projectSections(id).subscribe((sections) => {
          this.project.sections = sections;
        });
      });
    });
  }
}
