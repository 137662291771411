import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-signup-modal',
  templateUrl: './signup-modal.component.html',
  styleUrls: ['./signup-modal.component.css']
})
export class SignupModalComponent implements OnInit {
  @Output() signUpSuccessful: EventEmitter<any> = new EventEmitter<any>();

  loading = false;
  redMessage: string = null;

  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit(): void {}

  private isInputValid(name, email, password) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (name.length === 0) {
      this.redMessage = 'Please enter your first name.';
      return false;
    } else if (!re.test(String(email).toLowerCase())) {
      this.redMessage = 'Please enter a valid email.';
      return false;
    } else if (password.length < 8) {
      this.redMessage = 'Please enter a password that is at least 8 characters.';
      return false;
    }

    return true;
  }

  async handleForm(e) {
    e.preventDefault();
    this.loading = true;

    const email = e.target.email.value;
    const password = e.target.password.value;
    let name = e.target.firstName.value;

    if (this.isInputValid(name, email, password)) {
      name = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

      const authMessage = await this.auth.signUpWithPassword(name, email, password);

      if (authMessage === 'success') {
        this.signUpSuccessful.emit();
        this.router.navigate(['/dashboard']);
      } else {
        this.redMessage = authMessage;
      }
    }

    this.loading = false;
  }
}
