import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.css']
})
export class HowItWorksComponent implements OnInit {

  image_srcs = ['/assets/img/landing-assets/how-it-works-1.png', '/assets/img/landing-assets/how-it-works-2.png', '/assets/img/landing-assets/how-it-works-3.png', '/assets/img/landing-assets/how-it-works-4.png'];
  image_captions = ['Building Charts with MatPlotLib and Python', 'Learn how to build the Brick Breaker game in iOS', 'Join our Slack Community for professional help', 'Become a Certified Developer with our Courses'];
  indexSelected = 0;

  constructor() {

  }

  ngOnInit(): void {
  }

  updateTopicImage(selectedTopic) {
    this.indexSelected = selectedTopic;
  }


}
