import {Component, OnInit} from '@angular/core';
import {YoutubeService} from '../../../services/youtube.service';
import {YoutubePlaylist} from '../../../models/YoutubePlaylist';

@Component({
  selector: 'app-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.css']
})
export class YoutubeComponent implements OnInit {

  playlists: YoutubePlaylist[];
  showAllVideos = false;

  constructor(private youtubeService: YoutubeService) {
  }

  ngOnInit() {
    this.youtubeService.loadAllPlaylists().then((val) => {
      this.playlists = val;
    });
  }

  goToPlaylist(playlistID) {
    location.href = './youtube-videos/' + playlistID + '/0';
  }

}
