import {Component, OnInit, Input} from '@angular/core';
import {Course} from '../../../models/Course';

@Component({
  selector: 'coursecard',
  templateUrl: './coursecard.component.html',
  styleUrls: ['./coursecard.component.css']
})
export class CoursecardComponent implements OnInit {

  @Input('home') home: Boolean;
  @Input('course') course: Course;
  @Input('url') url: string;

  constructor() {
  }

  ngOnInit() {
  }
}

