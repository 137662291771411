import {Component, OnInit, Input, EventEmitter, Output, ViewChild, TemplateRef, AfterViewInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {UserService} from '../../../services/user.service';
import {Router, RoutesRecognized} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AppUser} from '../../../models/AppUser';
declare var createDrift: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, AfterViewInit {
  @Output() userLoaded: EventEmitter<AppUser> = new EventEmitter();
  didScroll = false;
  isCollapsed = true;

  resetEmailSent = false;

  success = false;
  userEmail = '';

  appUser: AppUser;
  loadedAuth = false;
  navbarStyle = null;
  pageStyle = null;
  showBanner = true;
  hideBannerOnPage = false;
  private navStyle = navStyle;

  constructor(
    private auth: AuthService,
    private router: Router,
    private userService: UserService,
    private modalService: NgbModal
  ) {
    auth.appUser$.subscribe((appUser) => {
      this.appUser = appUser;
      this.userService.user = this.appUser;
      this.loadedAuth = true;
      // setInterval(this.countdownTime, 1000);
    });
  }

  ngOnInit() {
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        this.navbarStyle = data.state.root.firstChild.data.navAttr;
        this.pageStyle = this.navbarStyle;

        if (!this.pageStyle) {
          this.hideBannerOnPage = true;
        } else {
          this.hideBannerOnPage = true;
        }
      }
    });
    window.addEventListener('scroll', this.scroll);

  }

  ngAfterViewInit(): void {
    createDrift();
  }

  openVerticallyCentered(content) {
    const modal = this.modalService.open(content, {centered: true});
    modal.result.then(
      () => {},
      () => {
        this.success = false;
        this.userEmail = '';
      }
    );
  }

  countdownTime() {
    if (!this.hideBannerOnPage || this.showBanner) {
      const difference = +new Date('2020-07-30') - +new Date();

      const parts = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
        secondsString: '' + Math.floor((difference / 1000) % 60),
        minutesString: '' + Math.floor((difference / 1000 / 60) % 60),
        hoursString: '' + Math.floor((difference / (1000 * 60 * 60)) % 24)
      };

      if (parts.seconds < 10) {
        parts.secondsString = '0' + parts.seconds.toString();
      }

      if (parts.minutes < 10) {
        parts.minutesString = '0' + parts.minutes.toString();
      }

      if (parts.hours < 10) {
        parts.hoursString = '0' + parts.hours.toString();
      }

      try {
        document.getElementById('discount-timer').innerHTML =
          'Ends in ' + parts.days + ':' + parts.hoursString + ':' + parts.minutesString + ':' + parts.secondsString;
      } catch (e) {}
    }
  }

  hideBanner() {
    this.showBanner = false;
  }

  scroll = (): void => {
    if (window.innerWidth > 992) {
      // tslint:disable-next-line:max-line-length
      this.didScroll =
        window.scrollY + 10 + document.getElementById('navbar_main').offsetHeight >
        document.getElementById('sectionHeader').offsetHeight;
      if (this.didScroll) {
        this.navbarStyle = navStyle.blueTextWhiteBg;
      } else {
        this.navbarStyle = this.pageStyle;
      }
    } else {
      document.getElementById('navbar_main').style.position = 'fixed';
      this.didScroll = true;
    }
  };

  logoutUser() {
    this.auth.logout();
  }

  showResetEmailSent(email): void {
    this.resetEmailSent = true;
    this.userEmail = email;
  }

  showEmailSentSuccessfully(email): void {
    this.success = true;
    this.userEmail = email;
  }
}

export enum navStyle {
  whiteTextBlueBg,
  blueTextWhiteBg,
  whiteTextTransparentBg,
  whiteTextBlackBg
}
