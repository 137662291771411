import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {PersonalInfo} from '../../../models/AppUser';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
  }

  login(u: PersonalInfo) {
    this.authService.login(u.email, u.password);
  }
}
