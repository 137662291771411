import {Component, OnInit} from '@angular/core';
import {AllCourseCalendar, Calendar, CourseCalendar} from '../../../models/AppUser';
import {UserService} from '../../../services/user.service';
import {Chart} from 'chart.js';
import {Dataset, UserDatasets} from '../../../models/Chart';
import {ActivatedRoute} from '@angular/router';
import {Class} from '../../../models/Class';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-teacher-analytics',
  templateUrl: './teacher-analytics.component.html',
  styleUrls: ['./teacher-analytics.component.css']
})
export class TeacherAnalyticsComponent implements OnInit {

  calendar: AllCourseCalendar;
  chart = [];
  uids = [];
  userData: Array<UserDatasets> = [];
  classID: string;
  class: Class;
  keys = [];
  timeout = false;
  showScreen = 0;
  userSelected: UserDatasets;
  objectKeys = Object.keys;
  colorArray = ['#FFC000', '#9966CC', '#FBCEB1', '#7FFFD4', '#007FFF', '#89CFF0', '#F5F5DC', '#000000', '#0000FF', '#0095B6', '#8A2BE2',
    '#DE5D83', '#CD7F32', '#964B00', '#800020', '#702963', '#960018', '#DE3163', '#007BA7', '#F7E7CE', '#7FFF00', '#7B3F00', '#0047AB',
    '#6F4E37', '#B87333', '#FF7F50', '#DC143C', '#00FFFF', '#EDC9Af', '#7DF9FF', '#50C878', '#00FF3F', '#FFD700', '#808080', '#008000',
    '#3FFF00', '#4B0082', '#FFFFF0', '#00A86B', '#29AB87', '#B57EDC', '#FFF700', '#C8A2C8', '#BFFF00', '#FF00FF', '#E0B0FF', '#000080',
    '#CC7722', '#808000', '#FF6600', '#FF4500', '#DA70D6', '#FFE5B4', '#D1E231', '#CCCCFF', '#1C39BB', '#FD6C9E', '#8E4585', '#003153',
    '#CC8899', '#800080', '#E30B5C', '#FF0000', '#C71585', '#FF007F', '#E0115F', '#E0115F', '#E0115F', '#E0115F', '#E0115F', '#E0115F',
    '#E0115F', '#E0115F', '#E0115F', '#E0115F', '#E0115F', '#708090', '#A7FC00', '#00FF7F', '#D2B48C', '#483C32', '#008080', '#40E0D0',
    '#3F00FF', '#7F00FF', '#40826D', '#FFFFFF', '#FFFF00'];
  private modalRef: NgbModalRef;

  constructor(private userService: UserService, private route: ActivatedRoute, private modalService: NgbModal) {
  }

  ngOnInit() {

    this.route.paramMap
      .subscribe(params => {
        // console.log(params);
        this.classID = params.get('classid');
        this.userService.getClass(this.classID).then(val => {
          if (!val) {
            location.href = '/teacher-dashboard/';
          } else {
            this.class = val;
          }
        });
      });

    setTimeout(() => {
      this.timeout = true;
      if (this.userData.length !== 0) {
        this.createGraph(this.userData.map(a => a.dataset));
      }
    }, 3000);
  }

  changeScreen(num: number) {
    this.showScreen = num;
  }

  updateClass() {
    this.userService.updateClass(this.class);
  }

  deleteClass() {
    if (confirm('Are you sure you want to delete your class?')) {
      this.userService.deleteClass(this.class, this.userService.user.personalInfo.uid);

    }
  }

  getCalendar() {

    const idsRef = this.userService.loadAllStudentsCalendarData(this.classID);
    idsRef.subscribe((users) => {
      users.forEach((user) => {
        const uid = user.personalInfo.uid;
        if (this.uids.includes(uid)) {
          return;
        }
        this.uids.push(uid);
        this.userService.loadAllCalendarData(uid).then(data => {

          function formatDate(date) {
            let dd = date.getDate();
            let mm = date.getMonth() + 1;
            let yyyy = date.getFullYear();
            if (dd < 10) {
              dd = '0' + dd;
            }
            if (mm < 10) {
              mm = '0' + mm;
            }
            date = mm + '-' + dd + '-' + yyyy;
            return date;
          }


          function Last7Days() {
            let result = [];
            for (let i = 0; i < 7; i++) {
              let d = new Date();
              d.setDate(d.getDate() - i);
              result.push(formatDate(d));
            }

            return result.reverse();
          }

          this.keys = Last7Days();

          this.calendar = <AllCourseCalendar>data;
          const values = [];
          const sum = 0.0;
          this.keys.forEach((val) => {
            let sum = 0;
            if (val in this.calendar) {
              console.log(val);
              const dayCal = this.calendar[val];
              const courses = Object.keys(dayCal);
              courses.forEach((course) => {
                const videos = dayCal[course];
                const vidIds = Object.keys(videos);
                vidIds.forEach((v) => {
                  sum += videos[v].durationWatched;
                });
              });
              values.push(+(sum / 60.0).toFixed(1));
            } else {
              values.push(0);
            }
          });

          const color = Math.floor(Math.random() * 16777216).toString(16);

          const d = {
            label: user.personalInfo.firstName + ' ' + user.personalInfo.lastName,
            data: values,
            backgroundColor: this.colorArray[this.userData.length],
          };

          const uD = {
            calendar: <AllCourseCalendar>data,
            name: user.personalInfo.firstName + ' ' + user.personalInfo.lastName,
            email: user.personalInfo.email,
            dataset: d
          };

          this.userData.push(uD);

        }).then((res) => {
          console.log('Finished loading all user data');
        });
      });

    });
  }

  showUserData(u: UserDatasets, content) {
    this.userSelected = u;
    console.log(this.userSelected.calendar);

    this.modalRef = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

  closeModal() {
    this.modalRef.close();
  }

  getSum(date) {
    let sum = 0.0;
    const dayCal = this.userSelected.calendar[date];
    const courses = Object.keys(dayCal);
    courses.forEach((course) => {
      const videos = dayCal[course];
      const vidIds = Object.keys(videos);
      vidIds.forEach((v) => {
        sum += Math.round(videos[v].durationWatched / 60.0);
      });
    });
    return sum;
  }


  createGraph(datasets) {
    this.chart = new Chart('canvas', {
      type: 'bar',
      data: {
        labels: this.keys,
        datasets: datasets
      },
      options: {
        legend: {
          labels: {
            fontColor: 'black',
            fontSize: 16,
            fontFamily: 'Lato',
          }
        },
        scales: {
          xAxes: [{
            stacked: true,
            display: true,
            ticks: {
              autoSkip: false,
              max: 4,
            }
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }

  sum(data: Array<number>) {
    return (data.reduce((a, b) => a + b, 0.0));
  }
}
