import {Component, OnInit} from '@angular/core';
import {CourseService} from '../../../services/course.service';
import {Observable} from 'rxjs/Observable';
import {Course} from '../../../models/Course';
import {Project} from '../../../models/Project';
import {ProjectService} from '../../../services/project.service';
import {ActivatedRoute} from '@angular/router';
import {Angulartics2Amplitude} from 'angulartics2/amplitude';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-catalog-page',
  templateUrl: './catalog-page.component.html',
  styleUrls: ['./catalog-page.component.css']
})
export class CatalogPageComponent implements OnInit {

  courses: Observable<Course[]>;
  showCourses: boolean;
  projects: Observable<Project[]>;

  constructor(private cs: CourseService, private ps: ProjectService, private route: ActivatedRoute, private angulartics2Amplitude: Angulartics2Amplitude, private titleService: Title, private metaService: Meta) {
    angulartics2Amplitude.pageTrack('catalog');

    this.titleService.setTitle('Catalog - TheCodex');
    this.metaService.updateTag({name: 'description', content: 'View our Catalog of Courses and Projects'});

    this.courses = cs.Courses.map((data) => {
      data.sort((a, b) => {
        return a.order < b.order ? -1 : 1;
      });
      return data;
    });
    this.projects = ps.Projects.map((data) => {
      for (var proj of data) {
        if (proj.order == null) {
          proj.order = data.length;
        }
      }
      data.sort((a, b) => {
        return a.order < b.order ? -1 : 1;
      });
      return data;
    });
  }

  ngOnInit(): void {
    this.route.fragment.subscribe((fragment: string) => {
      this.showCourses = fragment === 'courses';
    });
  }


}
