import {Component, OnInit} from '@angular/core';
import {UserService} from './services/user.service';
import {AuthService} from './services/auth.service';
import {Router} from '@angular/router';
import {Angulartics2Amplitude} from 'angulartics2/amplitude';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  constructor(private userService: UserService, private auth: AuthService, router: Router, angulartics2Amplitude: Angulartics2Amplitude) {
    angulartics2Amplitude.startTracking();

    auth.user$.subscribe(user => {
      if (user) {
        angulartics2Amplitude.setUsername(user.uid);
        angulartics2Amplitude.setUserProperties({'active-today': true});
        this.userService.getUserDetails(user.uid).then(res => {
          angulartics2Amplitude.setUserProperties({'email': this.userService.currentUser.personalInfo.email});
          if (this.userService.currentUser.proMembership.status === 'active' && this.userService.currentUser.proMembership.coupon === false) {
            angulartics2Amplitude.setUserProperties({'pro-membership-active': true});
          }
        });
      }
    });

  }
}
