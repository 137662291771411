import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Course} from '../../../models/Course';
import {CourseService} from '../../../services/course.service';
import {UserService} from '../../../services/user.service';
import {CurrentLecture} from '../../../models/Lecture';
import {Content} from '../../../models/Content';
import {ContentType} from '../../../services/content.service';

@Component({
  selector: 'app-coursehome',
  templateUrl: './coursehome.component.html',
  styleUrls: ['./coursehome.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CoursehomeComponent implements OnInit {

  course: Course;
  collapse = false;
  viewedLectures = [1];
  lastLecture: CurrentLecture;
  percentCompleted: number;
  loadedUser = false;

  constructor(private route: ActivatedRoute, private router: Router, private cs: CourseService, private userService: UserService) {
  }

  ngOnInit() {
    this.route.paramMap
      .subscribe(params => {
        // console.log(params);
        const id = params.get('courseid');
        this.cs.loadEntireCourse(id).then(c => {
          this.course = c;

          this.userService.currentUser$.subscribe(appUser => {
            if (appUser) {
              this.updateLastLecture();
              this.loadedUser = true;
            }
          });
        });
      });
  }

  checkLectureID(lecID: number) {
    this.viewedLectures = this.userService.getViewedLectures(this.course.courseID, ContentType.course);
    return this.viewedLectures.includes(lecID);
  }

  // This function is called once the user data has arrived
  updateLastLecture() {
    this.lastLecture = this.userService.getLastLecture(this.course as unknown as Content, ContentType.course);
    this.updateProgressBar();
  }

  goToLastLecture() {
    location.href = '/courses/' + this.course.courseID + '/' + this.lastLecture.sectionIndex + '/lecture/' + this.lastLecture.lecture.videoID;
  }

  goToLecture(sectionid: number, videoid: number) {
    location.href = '/courses/' + this.course.courseID + '/' + sectionid + '/lecture/' + videoid;
  }

  updateProgressBar() {
    const userNum = this.userService.getCountOfViewedLectures(this.course.courseID, ContentType.course) - 1;
    this.percentCompleted = Math.round(userNum / this.course.lectures * 100);
  }


}
