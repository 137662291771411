import {Component, Input, OnInit} from '@angular/core';
import {Course} from '../../../models/Course';

@Component({
  selector: 'app-coursedetailcard',
  templateUrl: './coursedetailcard.component.html',
  styleUrls: ['./coursedetailcard.component.css']
})
export class CoursedetailcardComponent implements OnInit {

  @Input('home') home: Boolean;
  @Input('course') course: Course;
  @Input('url') url: string;

  constructor() { }

  ngOnInit(): void {
  }

}
