import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-imagebar',
  templateUrl: './imagebar.component.html',
  styleUrls: ['./imagebar.component.css']
})
export class ImagebarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
